import React, { Fragment, useContext, useEffect, useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import stylebtn from '../../styles/btn.module.scss';
import TitleH1Component from '../../components/TitleH1Component';
import stylegeneral from '../../styles/general.module.scss';
import { Icon } from '@iconify/react/dist/iconify.js';
import styleBtn from '../../styles/btn.module.scss';
import ParrafoComponent from '../../components/ParrafoComponent';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';
import InputComponent from '../../components/InputComponent';
import SelectComponent from '../../components/SelectComponent';
import { Link, useParams } from 'react-router-dom';
import { EditarProducto, EliminarProducto, EliminarPuesto, getPuestoByID, PostProducto } from '../../api/ApiVenta';
import LoaderComponent from '../../components/LoaderComponent';
import { getClases } from '../../api/Clase';
import { getTipos } from '../../api/Tipo';
import AlertComponent from '../../components/AlertComponent';
import { UserContext } from '../../context/UserContext';
import PaginadorComponent from '../../components/PaginadorComponent';
import MoneyInputComponent from '../../components/MoneyInputComponent';
const ProductPage = () => {
	const { id } = useParams();
	const [show, setShow] = useState(false);
	const [showprduct, setShowProduct] = useState(false);
	const [showEliminar, setShowEliminar] = useState(false);
	const [pustoaction, setpustoaction] = useState(false);
	const [mensaje, setMensaje] = useState('');
	const [loading, setLoading] = useState(false);
	const [numeroPuesto, setnumeroPuesto] = useState('');
	const [idbloque, setidbloque] = useState('');
	const [nombreBloque, setNombreBloque] = useState('');
	const [optionsclass, setOptionsClass] = useState([]);
	const [selectedOptionClass, setSelectedOptionClass] = useState(null);
	const [optionstype, setOptionstype] = useState([]);
	const [selectedOptionType, setSelectedOptionType] = useState(null);
	const [dataProducto, setDataProducta] = useState([]);
	const [idProducto, setIdproducto] = useState('');
	const [alert, setAlert] = useState(false);
	const [currentProducto, setCurrentProducto] = useState([]);
	const [carrito, setCarrito] = useState([]);
	const [cantidadVenta, setCantidadVenta] = useState('');
	const { setNumCarrito } = useContext(UserContext);
	const [currentPage, setCurrentPage] = useState(0);
	const [formData, setFormData] = useState({
		dimension: '',
		cantidad: '',
		precioCompra: '',
		precioVenta: '',
	});
	const handleClose = () => setShow(false);
	const handleShow = (item) => {
		setShow(true);
		setpustoaction(false);
		setCurrentProducto(item);
	};

	// paginador del prodecto
	const itemsPerPage = 2;
	const pageCount = Math.ceil(dataProducto.length / itemsPerPage);
	const currentPageDataproducto = dataProducto.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

	// Metodo agregar el carrito de compra
	const Aggcompra = () => {
		if (cantidadVenta > currentProducto?.cantidad) {
			setMensaje(`Cantidad es mayor a la disponibles, disponible solo ${currentProducto?.cantidad} unidades`);
		} else if (cantidadVenta <= 0) {
			setMensaje(`la cantidad ingresada es incorrecta `);
		} else {
			// Obtener el carrito actual desde localStorage (si no existe, inicializar como array vacío)
			const carritoActual = JSON.parse(localStorage.getItem('carrito')) || [];

			// Verificar si el producto ya existe en el carrito
			const productoExistente = carritoActual.find((producto) => producto.id === currentProducto?.id);

			if (productoExistente) {
				// Validar si la cantidad nueva más la existente supera el stock disponible
				const nuevaCantidad = Number(productoExistente.cantidad) + Number(cantidadVenta);
				if (nuevaCantidad > currentProducto?.cantidad) {
					// Mostrar mensaje de error si la cantidad supera el stock disponible
					setMensaje(`Producto se encuentra disponible en el carrito de compras, verificar cantidad `);
				} else {
					// Actualizar la cantidad y el valor total si la validación es correcta
					productoExistente.cantidad = nuevaCantidad;
					productoExistente.valorTotalPorProducto = nuevaCantidad * currentProducto?.precioVenta;
					setpustoaction(true);
				}
			} else {
				// Si no existe, crear el nuevo objeto de compra
				const nuevoProducto = {
					id: currentProducto?.id,
					bloque: nombreBloque,
					puesto: numeroPuesto,
					clase: currentProducto?.Clase?.name,
					tipo: currentProducto?.Tipo?.name,
					dimension: currentProducto?.dimension,
					cantidad: cantidadVenta,
					valorTotalPorProducto: cantidadVenta * currentProducto?.precioVenta,
					cantidadTotal: currentProducto?.cantidad,
					valorPorUnidad: currentProducto?.precioVenta,
					pendientePorEntregar: 0,
				};

				// Agregar el nuevo producto al carrito
				carritoActual.push(nuevoProducto);
				setpustoaction(true);
			}

			// Actualizar el localStorage con el carrito actualizado
			localStorage.setItem('carrito', JSON.stringify(carritoActual));

			// Actualizar el estado del carrito en React
			setCarrito(carritoActual);
			setNumCarrito(carritoActual.length);
		}
		setTimeout(() => {
			setMensaje('');
		}, 5000);
	};

	// Manejar el cambio en el input
	const handleInputcantidad = (event) => {
		setCantidadVenta(event.target.value);
	};

	useEffect(() => {
		getClases()
			.then((response) => {
				const formattedOptions = response?.data?.map((item) => ({
					value: item.id,
					label: item.name,
				}));
				setOptionsClass(formattedOptions);
			})
			.catch((error) => {
				console.log(error.response);
			});
	}, []);

	useEffect(() => {
		getTipos()
			.then((response) => {
				const formattedOptions = response?.data?.map((item) => ({
					value: item.id,
					label: item.name,
				}));
				setOptionstype(formattedOptions);
			})
			.catch((error) => {
				console.log(error.response);
			});
	}, []);

	// Metodos control del modal para agregar el producto al puesto
	const handleCloseProduct = () => {
		setShowProduct(false);
		setFormData({
			dimension: '',
			cantidad: '',
			precioCompra: '',
			precioVenta: '',
		});
		setSelectedOptionType(null);
		setSelectedOptionClass(null);
	};
	//const handleShowProduct = () => setShowProduct(true);

	// Metodos para el popup de eliminar
	const handleCloseEliminar = () => setShowEliminar(false);
	// const handleShowEliminar = () => setShowEliminar(true);

	// Metodo eliminar un puesto
	const ShoweliminarPuesto = () => {
		setpustoaction(true);
		setShowEliminar(true);
	};
	// Metodo eliminar un producto
	const modaleliminarProducto = (id) => {
		setpustoaction(false);
		setShowEliminar(true);
		setIdproducto(id);
	};
	// Metodo editar un producto
	const modalelditarProducto = (item) => {
		setpustoaction(true);
		setShowProduct(true);
		setCurrentProducto(item);
		setFormData({
			dimension: item?.dimension,
			cantidad: item?.cantidad,
			precioCompra: item?.precioCompra,
			precioVenta: item?.precioVenta,
		});
	};

	const fetchpuesto = async () => {
		setLoading(true);
		try {
			const puesto = await getPuestoByID(id);
			// setData(bloque.data.Puestos);
			setnumeroPuesto(puesto?.data?.numero);
			setidbloque(puesto?.data?.bloqueId);
			setNombreBloque(puesto?.data?.Bloque?.name);

			const ProductosOrdenados = puesto?.data?.Productos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
			setDataProducta(ProductosOrdenados);

		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			fetchpuesto();
		}
	}, [id]);

	// Manejar el cambio en el input
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const eliminarPuesto = async () => {
		try {
			const response = await EliminarPuesto(id);
			console.log(response);
			setMensaje(response.msg);
			window.location.href = `/puesto/${idbloque}`;
		} catch (error) {
			console.error('Error algo esta mal:', error);
		}
		setTimeout(() => {
			setMensaje('');
		}, [5000]);
	};

	const addProducto = async () => {
		PostProducto(
			id,
			selectedOptionClass,
			selectedOptionType,
			formData.dimension,
			formData.cantidad,
			formData.precioCompra,
			formData.precioVenta
		)
			.then((response) => {
				setMensaje(response.msg);
				if (response.success) {
					setShowProduct(false);
					setAlert(true);
					fetchpuesto();
					setFormData({
						dimension: '',
						cantidad: '',
						precioCompra: '',
						precioVenta: '',
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
		setTimeout(() => {
			setAlert(false);
			setMensaje('');
		}, 5000);
	};

	const eliminarproducto = async () => {
		try {
			const res = await EliminarProducto(idProducto);
			if (res.success) {
				setAlert(true);
				setMensaje(res.message);
				handleCloseEliminar();
				fetchpuesto();
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setAlert(false);
		}, 5000);
	};

	const editarProducto = async () => {
		try {
			const res = await EditarProducto(
				currentProducto.id,
				selectedOptionClass,
				selectedOptionType,
				formData.dimension,
				formData.cantidad,
				formData.precioCompra,
				formData.precioVenta
			);
			if (res.success) {
				fetchpuesto();
				setAlert(true);
				setMensaje(res.message);
				handleCloseProduct();
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setAlert(false);
			setMensaje('');
		}, 4000);
	};

	const handleGoBack = () => {
		// Retrocede una página en el historial
		window.history.back();
	};

	return (
		<Fragment>
			<div className="d-flex justify-content-center">
				<AlertComponent styleGroup="bg-soccess color-white" mensaje={mensaje} activaalert={alert} />
			</div>
			<HeaderComponent styleGroup="">
				<TitleH1Component styleGroup="box-text color-dark fw700 roboto mb-4">B{nombreBloque ?? ''}</TitleH1Component>
				<button
					type="button"
					className={`${stylebtn.btn} ${stylebtn.primary} me-2 mt-1`}
					onClick={() => {
						setShowProduct(true);
						setpustoaction(false);
					}}
				>
					Crear Producto
				</button>
				<button type="button" className={`${stylebtn.btn} ${stylebtn.danger} mt-2`} onClick={ShoweliminarPuesto}>
					Eliminar puesto
				</button>
			</HeaderComponent>

			{loading ? (
				<LoaderComponent styleGroup="mt-5" />
			) : dataProducto.length !== 0 ? (
				<div className="conatiner-global pt-3 pb-5">
					<TitleH2Component styleGroup="box-text color-dark text-center fw700 WorkSans mb-0 pb-4">
						PUESTO {numeroPuesto}
					</TitleH2Component>
					<div className="d-flex justify-content-end">
						<button
							type='button'
							onClick={handleGoBack}
							className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-dark`}

						>
							<Icon icon="icon-park-solid:back" />
						</button>
					</div>

					{currentPageDataproducto?.map((item, index) => {
						return (
							<div className={`${stylegeneral.card_product} mb-3`} key={index}>
								<div className={`${stylegeneral.elementos}`}>
									Fecha de registro: <b>{new Date(item?.updatedAt).toLocaleString()}</b>
								</div>
								<div className={`${stylegeneral.elementos}`}>
									<ParrafoComponent styleGroup=" color-dark fw400 WorkSans">
										Clase de madera: <b>{item?.Clase.name}</b>
									</ParrafoComponent>
								</div>
								<div className={`${stylegeneral.elementos}`}>
									tipo de madera: <b>{item?.Tipo.name}</b>
								</div>
								<div className={`${stylegeneral.elementos}`}>
									Dimensión madera: <b>{item?.dimension}</b>
								</div>
								<div className={`${stylegeneral.elementos}`}>
									Cantidad: <b>{item?.cantidad}</b>
								</div>
								<div className={`${stylegeneral.elementos}`}>
									Precio de compra: <b>${Number(item?.precioCompra).toLocaleString('es-ES')}</b>
								</div>
								<div className={`${stylegeneral.elementos}`}>
									Precio de venta X unidad: <b>${Number(item?.precioVenta).toLocaleString('es-ES')}</b>
								</div>
								<div className={`${stylegeneral.elementos}`}>
									Ganancias aproximadas:{' '}
									<b>
										$
										{Number(item?.precioVenta * item?.cantidad - item?.precioCompra * item?.cantidad).toLocaleString(
											'es-ES'
										)}
									</b>
								</div>
								<div className={`d-flex justify-content-end`}>
									<button
										className={`${styleBtn.btn_desnudo} ${styleBtn.icon}  p-0 d-inline-flex`}
										onClick={() => handleShow(item)}
									>
										{' '}
										<Icon icon="typcn:shopping-cart" />
									</button>
									<button
										className={`${styleBtn.btn_desnudo} ${styleBtn.icon} ms-3 p-0 d-inline-flex`}
										onClick={() => modalelditarProducto(item)}
									>
										{' '}
										<Icon icon="raphael:edit" />
									</button>
									<button
										className={`${styleBtn.btn_desnudo} ${styleBtn.icon} ms-3 p-0 d-inline-flex ${item?.DetalleFacturas?.length !== 0 ? 'text-grey' : 'color-danger '
											}`}
										onClick={() => modaleliminarProducto(item?.id)}
										disabled={item?.DetalleFacturas?.length !== 0}
									>
										{' '}
										<Icon icon="majesticons:delete-bin" />
									</button>
								</div>
							</div>
						);
					})}

					{pageCount > 1 && (
						<PaginadorComponent
							setCurrentPage={setCurrentPage}
							pages={pageCount}
							styleGroup="d-flex justify-content-center  mb-5"
						/>
					)}
				</div>
			) : (
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mt-5 text-center WorkSans">
					SIN PRODUCTOS
				</TitleH2Component>
			)}

			{/** modal Registro de producto al carro de compra**/}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo={`${!pustoaction ? 'COMPRA' : '¡¡Agregado con éxito!!'}`}
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={`box-text ${pustoaction ? 'color-soccess' : 'color-madera'} fw700 roboto text-center WorkSans`}
					show={show}
					handleClose={handleClose}
				>
					<div className="container">
						{!pustoaction ? (
							<form action="">
								<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>
								<div className="row">
									<div className="col-12 col-sm-6">
										<InputComponent
											label="Clase de madera"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-2"
											htmlFor="bloque"
											type="text"
											value={currentProducto?.Clase?.name}
											name="clase"
											disabled
										/>
									</div>

									<div className="col-12 col-sm-6">
										<InputComponent
											label="tipo"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-2"
											htmlFor="cantidad"
											type="text"
											disabled
											value={currentProducto?.Tipo?.name}
											name="clase"
										/>
									</div>
									<div className="col-12 col-sm-6">
										<InputComponent
											label="Dimencion"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-2  "
											htmlFor="precio"
											type="text"
											disabled
											value={currentProducto?.dimension}
											name="dimension"
										/>
									</div>
									<div className="col-12 col-sm-6">
										<InputComponent
											label="Cantidad"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-2"
											htmlFor="precio"
											type="number"
											name="cantidad"
											value={cantidadVenta}
											onChange={handleInputcantidad}
										/>
									</div>
									<div className="col-12 col-sm-6">
										<InputComponent
											label="Total"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-2"
											htmlFor="precio"
											type="nomber"
											disabled
											value={Number(cantidadVenta * currentProducto?.precioVenta).toLocaleString('es-ES')}
										/>
									</div>
								</div>
								<div className="d-flex justify-content-end">
									<button type="button" onClick={handleClose} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
										Cancelar
									</button>
									<button type="button" onClick={Aggcompra} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
										Agregar
									</button>
								</div>
							</form>
						) : (
							<Fragment>
								<ul className={`${stylegeneral.listacompra}`}>
									{carrito.map((item, index) => {
										return (
											<li key={index}>
												{item.cantidad} - {item.tipo} de {item.clase} {item.dimension}{' '}
											</li>
										);
									})}
								</ul>
								<div className="d-flex justify-content-end align-items-end">
									<Link to={'/home'} className={` ${stylebtn.btn_desnudo} ${stylebtn.line} color-dark`}>
										Seguir comprando
									</Link>
									<Link to={'/carrito'} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
										Ir al carrito
									</Link>
								</div>
							</Fragment>
						)}
					</div>
				</ModalGeneralComponent>
			</section>

			{/** modal Registro de un product al puesto**/}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo={`${pustoaction ? 'EDITAR' : 'NUEVO'}  PRODUCTO`}
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showprduct}
					handleClose={handleCloseProduct}
				>
					<div className="container">
						<form action="">
							{!alert && (
								<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>
							)}

							<div className="row">
								<div className="col-4">
									<InputComponent
										label="Numero del puesto"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										htmlFor="bloque"
										type="text"
										value={numeroPuesto}
										name="puesto"
										onChange={handleInputChange}
										disabled
									/>
								</div>
								<div className="col-16"></div>
								<div className="col-6">
									<SelectComponent
										styleGroup="mb-2"
										label="Clase de madera"
										styleLabel="fw600 WorkSans color-madera"
										options={optionsclass}
										name="clase"
										value={selectedOptionClass}
										capOpcions={setSelectedOptionClass}
										defaultValue={currentProducto?.Clase?.name}
										isDisabled={currentProducto?.DetalleFacturas?.length > 0 && pustoaction ? true : false}
									/>
								</div>
								<div className="col-6">
									<SelectComponent
										styleGroup="mb-2"
										label="Tipo madera"
										styleLabel="fw600 WorkSans color-madera"
										options={optionstype}
										name="tipo"
										value={selectedOptionType}
										capOpcions={setSelectedOptionType}
										defaultValue={currentProducto?.Tipo?.name}
										isDisabled={currentProducto?.DetalleFacturas?.length > 0 && pustoaction ? true : false}
									/>
								</div>
								<div className="col-6">
									<InputComponent
										label="Dimension"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										htmlFor="cantidad"
										type="text"
										value={formData.dimension}
										name="dimension"
										onChange={handleInputChange}
										disabled={currentProducto?.DetalleFacturas?.length > 0 && pustoaction ? true : false}
									/>
								</div>

								<div className="col-6">
									<InputComponent
										label="Cantidad"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										htmlFor="cantidad"
										type="number"
										value={formData.cantidad}
										name="cantidad"
										onChange={handleInputChange}
									/>
								</div>
								<div className="col-6">
									<MoneyInputComponent
										label="Precio de compra"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										htmlFor="precio"
										type="number"
										value={formData.precioCompra}
										name="precioCompra"
										onChange={handleInputChange}
									/>
								</div>
								<div className="col-6">
									<ParrafoComponent styleGroup=" color-gris fw400 WorkSans">
										precio de compra total: {Number(formData.precioCompra * formData.cantidad).toLocaleString('es-ES')}{' '}
									</ParrafoComponent>
								</div>
								<div className="col-6">
									<MoneyInputComponent
										label="Precio de venta por unidad"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										htmlFor="precio"
										type="number"
										value={formData.precioVenta}
										name="precioVenta"
										onChange={handleInputChange}
									/>
								</div>
								<div className="col-6">
									<ParrafoComponent styleGroup=" color-gris fw400 WorkSans">
										precio total de venta es: {Number(formData.precioVenta * formData.cantidad).toLocaleString('es-ES')}
									</ParrafoComponent>
								</div>
							</div>
							<div className="d-flex justify-content-end">
								<button
									type="button"
									onClick={handleCloseProduct}
									className={`btn ${stylebtn.btn} ${stylebtn.danger} `}
								>
									Cancelar
								</button>
								{pustoaction ? (
									<button
										type="button"
										onClick={editarProducto}
										className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
									>
										Agregar
									</button>
								) : (
									<button
										type="button"
										onClick={addProducto}
										className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
									>
										Agregar
									</button>
								)}
							</div>
						</form>
					</div>
				</ModalGeneralComponent>
			</section>

			{/** modal eliminar un puesto y product**/}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEliminar}
					handleClose={handleCloseEliminar}
				>
					<form action="">
						<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>

						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">{`Seguro que quiere eliminar este  ${pustoaction ? `puesto ${numeroPuesto}` : 'producto'
							} ?`}</TitleH2Component>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEliminar} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button
								type="button"
								className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
								onClick={pustoaction ? eliminarPuesto : eliminarproducto}
							>
								SI
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>
		</Fragment>
	);
};

export default ProductPage;
