import React, { useState } from 'react';
import ModalGeneralComponent from './ModalGeneralComponent';
import TitleH2Component from './TitleH2Component';
import { Fragment } from 'react';
import stylebtn from '../styles/btn.module.scss';
import styleGeneral from '../styles/general.module.scss';
import TitleH4Component from './TitleH4Component';
import ParrafoComponent from './ParrafoComponent';
import InputComponent from './InputComponent';
import MoneyInputComponent from './MoneyInputComponent';

const ModalAbono = (props) => {
	const { mensaje, data, show, handleClose, create, textInput, value, name, onChange, editarAbono, eliminarAbono } = props;
	const [id, setId] = useState(null);
	const [abono, setAbono] = useState(0);
	const [showModal, setShowAbono] = useState(false);
	const [currentAbono, setCurrentAbono] = useState([]);

	const handleCloseAbono =()=>setShowAbono(false);

	const handleInputChange = (event) => {
		setAbono(event.target.value);
	};


	const actionEdit = (item) => {
		setId(item?.id)
		setAbono(item?.monto)
	}

	const actionDilete = (item) => {
		setCurrentAbono(item)
		setShowAbono(true)
	}

	const editar = (item) => {
		editarAbono(item, abono)
		setId(null);

	}
	const eliminar = () => {
		eliminarAbono(currentAbono)
		setShowAbono(false)
	}

	return (
		<Fragment>
			{/** Modal para ver registro de abonos*/}
			<ModalGeneralComponent
				styleGroup={`d-flex align-items-center modal-general ${!create && 'modal-table'}`}
				styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
				show={show}
				handleClose={handleClose}
			>
				{!create ? (
					<div className="container">
						<div className="d-flex align-items-start justify-content-between">

							<div className={`table-responsive mt-4 ${styleGeneral.scrollElement} w-100`}>
								<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>

								<table className={`table table-bordered tableGeneral`}>

									<thead>
										<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-2">ABONADO</TitleH2Component>
										<tr>
											<th scope="col " className="color-madera">
												#
											</th>
											<th scope="col" className="color-madera">
												Fecha
											</th>
											<th scope="col" className="color-madera">
												Cantidad
											</th>
											<th scope="col" className="color-madera">
												Editar
											</th>

											<th scope="col" className="color-madera">
												Eliminar
											</th>
										</tr>
									</thead>
									{data.length != 0 ? <tbody>
										{data?.map((item, index) => {
											return (
												<tr key={index}>
													<th className='text-center'>{index + 1}</th>
													<td className='text-center'>{item?.formattedDate}</td>
													<td className='text-center'>
														{id === item?.id ? <MoneyInputComponent
															styleLabel="fw600 WorkSans"
															styleGroup=" mb-0"
															//htmlFor="abono"
															type="text"
															value={abono}
															name="abono"
															onChange={handleInputChange}
														/> :
															item?.formattedPrice
														}

													</td>
													<td className='text-center'>
														{id !== item?.id ? <button type="button" className={`btn ${stylebtn.btn} ${stylebtn.primary} `} onClick={() => actionEdit(item)}>
															Editar
														</button> :
															<button type="button" className={`btn ${stylebtn.btn} ${stylebtn.primary} `} onClick={() => editar(item)}>
																OK
															</button>}
													</td>
													<td className='text-center'>
														<button type="button" className={`btn ${stylebtn.btn} ${stylebtn.danger} `} onClick={() => actionDilete(item)}>
															Eliminar
														</button>
													</td>
												</tr>
											);
										})}
									</tbody> : <tbody>
										<tr><td colSpan={5} className='text-center'>SIN ABONOS</td></tr>
									</tbody>}
								</table>
							</div>
						</div>
						<div className="d-flex w-100 justify-content-end aling-items-start">
							<div className=" mt-4">
								<button
									type="button"
									className={` ${stylebtn.btn_desnudo} ${stylebtn.line} p-0 me-3 `}
									onClick={handleClose}
								>
									Salir
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className="container">
						<form action="">
							<div className="d-flex align-items-start justify-content-between">
								<div>
									<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
										DEM JOSE
									</TitleH4Component>
									<ParrafoComponent styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
										PEREZ MADARIAGA
									</ParrafoComponent>
									<ParrafoComponent styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
										CC:1234567
									</ParrafoComponent>
									<ParrafoComponent styleGroup="box-text color-dark fw700 roboto mb-5 text-center">
										OPERARIO
									</ParrafoComponent>
								</div>
								<TitleH4Component styleGroup="mb-0 me-3">23/10/2024</TitleH4Component>
							</div>

							<div className="d-flex align-items-center justify-content-between mb-4">
								<TitleH4Component styleGroup="mb-0 me-3">{textInput}</TitleH4Component>
								<InputComponent
									styleLabel="fw600 WorkSans"
									styleGroup={`${styleGeneral.col}`}
									htmlFor="bloque"
									type="number"
									name={name}
									onChange={onChange}
									value={value}
								/>
							</div>

							<div className="d-flex w-100 justify-content-end aling-items-start">
								<div className=" mt-4">
									<button
										type="button"
										className={` ${stylebtn.btn_desnudo} ${stylebtn.line} p-0 me-3 `}
										onClick={handleClose}
									>
										CANCELAR
									</button>
									<button type="submit" className={`btn ${stylebtn.btn} ${stylebtn.primary} `}>
										OK
									</button>
								</div>
							</div>
						</form>
					</div>
				)}
			</ModalGeneralComponent>

			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo={`DESEA ELIMINAR EL ABONO ?`}
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showModal}
					handleClose={handleCloseAbono}
				>
					<div className="container">
						<form action="">
							<div className="d-flex w-100 justify-content-end aling-items-start">
								<div className=" mt-4">
									<button
										type="button"
										className={` ${stylebtn.btn_desnudo} ${stylebtn.line} p-0 me-3 `}
										onClick={handleCloseAbono}
									>
										CANCELAR
									</button>
									<button
										type="button"
										onClick={eliminar}
										className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
									>
										OK
									</button>
								</div>
							</div>
						</form>
					</div>
				</ModalGeneralComponent>
			</section>
		</Fragment>
	);
};

export default ModalAbono;
