import instance from "../config";

// metodo para trae toda las clases

const getTipos = async () => {

    try {
        const response = await instance.get('/api/tipo');
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

const getTipoByID = async (id) => {
    try {
        const response = await instance.get(`/api/tipo/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

// Metodo editar clase
const EditarTipo = async (id, name) => {
    try {
        const response = await instance.put(`/api/tipo/${id}`, { name });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// Metodo de eliminar el bloque

const EliminarTipo = async (id) => {
    try {
        const response = await instance.delete(`/api/tipo/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}

// crear un puesto a su blouqe 

const PostTipo = async (name) => {
    try {
        const response = await instance.post('/api/tipo', { name });
        return response.data;
    } catch (error) {
        return error.response.data;

    }


}

export {
    getTipos,
    getTipoByID,
    EditarTipo,
    EliminarTipo,
    PostTipo,
}