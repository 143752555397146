import React, { Fragment, useState } from "react";
import stylegeneral from '../../styles/general.module.scss'
import PictureComponent from "../../components/PictureComponent";
import styleform from '../../styles/form.module.scss';
import stylebtn from '../../styles/btn.module.scss';
import { Login } from "../../api/Autenticacion";
import ParrafoComponent from "../../components/ParrafoComponent";
import LoaderComponent from "../../components/LoaderComponent";
const LoginPage = () => {

    const [errorr, setError] = useState('');
    const [loading, setloading] = useState(false);
    const [formData, setFormData] = useState({
        usernombre: '',
        passward: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };



    // codigo javaStrip
    const login = () => {
        setloading(true);
        Login(formData.usernombre, formData.passward).then((response) => {
            sessionStorage.setItem('token', response.data.token);
           window.location.href = "/home"
            sessionStorage.setItem(sessionStorage.getItem('token'), response.success);
            setloading(false);
            localStorage.setItem('iduser', response.data.user.id);
            localStorage.setItem('notificacioneslocal', true);
        })
            .catch(error => {
                if (error.response) {
                    setloading(false);
                    // Error de respuesta del servidor
                    setError(error.response.data.msg);
                    console.log(error.response);
                    sessionStorage.removeItem(sessionStorage.getItem('token'));
                }
            });
    }

    return (
        <Fragment>
            <div className={`${stylegeneral.fondo_img} ${stylegeneral.login_fondo}`}>
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className={`${styleform.loginContainer}`}>
                        {loading ? <LoaderComponent styleGruop="h-auto" /> :
                            <Fragment>
                                <div className="d-flex justify-content-center mb-4">
                                    <PictureComponent src="/img/logo.png" alt="logo" styleGroup="w-auto" />
                                </div>
                                <form action="">
                                    <ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{errorr}</ParrafoComponent>

                                    <div className={`${styleform.containar_Input} mb-4`}>
                                        <label htmlFor="username" className="fw400 WorkSans">Usuario</label>
                                        <input
                                            type="text"
                                            name="usernombre"
                                            value={formData.usernombre}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className={`${styleform.containar_Input} mb-4`}>
                                        <label htmlFor="pass" className="fw400 WorkSans">contraseña</label>
                                        <input
                                            type="password"
                                            name="passward"
                                            value={formData.passward}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                   {/*} <div className="form-check d-flex justify-content-center mb-3">
                                        <input className="form-check-input me-2" type="checkbox" value="" id="recorddata" />
                                        <label className="form-check-label" htmlFor="recorddat">
                                            Recordar contraseña
                                        </label>
                                    </div>*/}

                                    <div className="d-flex justify-content-center pb-4">
                                        <button type="button" onClick={login} className={`${stylebtn.btn} ${stylebtn.primary}`}>Ingresar</button>
                                    </div>
                                </form>
                            </Fragment>

                        }

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default LoginPage;