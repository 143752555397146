import instance from "../config";

// metodo para traer todo los registos de caja menor 

const getCajamenor = async () => {

    try {
        const response = await instance.get('/api/cajamenor');
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

// metodo para regstrar caja menor 

const Postcajamenor = async (data) => {
    try {
        const response = await instance.post('/api/cajamenor', data);
        return response.data;
    } catch (error) {
        return error.response.data;

    }
}


// metodo para editar caja menor 

const Editcajamenor = async (id,producto,precio) => {
    console.log(id)
    try {
        const response = await instance.put(`/api/cajamenor/${id}`, {producto,precio});
        return response.data;
    } catch (error) {
        return error.response.data;

    }
}

const EliminarCajamenor = async (id) => {
    try {
        const response = await instance.delete(`/api/cajamenor/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}

export {
    getCajamenor,
    Postcajamenor,
    Editcajamenor,
    EliminarCajamenor,
}