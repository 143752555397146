import React, { useState, useEffect } from 'react';
import styleform from '../styles/form.module.scss';

const MoneyInputComponent = (props) => {
	const { value, onChange, styleGroup, name, styleLabel, htmlFor, label, search, styleinput, id, disabled } = props;

	// Estado local para almacenar el valor sin formato mientras el usuario edita
	const [rawValue, setRawValue] = useState(value || '');

	useEffect(() => {
		// Si el valor cambia desde el componente padre, actualiza el estado interno
		if (value !== rawValue) {
			setRawValue(value || '');
		}
	}, [value]);

	const handleFocus = () => {
		// Mostrar el valor sin formatear cuando el usuario hace foco en el campo
		setRawValue(value || '');
	};

	const handleChange = (event) => {
		// Actualizar el valor sin formatear, permitiendo solo números
		const inputValue = event.target.value.replace(/\D/g, '');
		setRawValue(inputValue);
		// Actualizar el valor en el estado del componente padre
		onChange({ target: { name, value: inputValue } });
	};

	const handleBlur = () => {
		// Formatear el valor solo si es válido
		if (rawValue && !isNaN(rawValue)) {
			setRawValue(`$ ${Number(rawValue).toLocaleString('es-ES')}`);
		} else if (!rawValue) {
			// Si el valor está vacío, no lo formatea ni lo asigna a `$ 0`
			setRawValue('');
		}
	};

	// Mostrar el valor actual sin formato si el campo tiene foco, y formateado si no tiene foco
	const displayValue = document.activeElement.id === id ? rawValue : (rawValue && !isNaN(rawValue) ? `$ ${Number(rawValue).toLocaleString('es-ES')}` : rawValue || '');

	return (
		<div className={`${styleform.input_fuera} ${styleGroup}`}>
			{label ? (
				<label htmlFor={htmlFor} className={`${styleLabel}`}>
					{label}
				</label>
			) : null}
			<input
				id={id}
				className={`${!search && 'form-control'} ${styleinput}`}
				type="text"
				value={displayValue}
				name={name}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				placeholder="$ 0"
				disabled={disabled}
			/>
		</div>
	);
};

export default MoneyInputComponent;