import React, { useEffect, useState } from "react";
import ModalGeneralComponent from "./ModalGeneralComponent";
import PaginadorComponent from "./PaginadorComponent";
import stylebtn from '../styles/btn.module.scss';
const ModalNotificacionComponent = (props) => {
    const { showNotificacion,
        handleClaseNotificacion,
        abrirNotificaciones,
        currentPageDataNotificaciones,
        pageCountNoti, setCurrentPage,
        cantidadFacurasabiertas } = props;

    const [fecha, setFecha] = useState('');

    const capturarFecha = () => {
        const hoy = new Date();
        const fechaFormateada = hoy.toISOString().split('T')[0]; // Formato YYYY-MM-DD
        setFecha(fechaFormateada);
    };

    useEffect(() => {
        capturarFecha();
    }, [])

    return (
        <ModalGeneralComponent
            ModalHeader
            titulo={`Facturas  pendiente de pago  ${cantidadFacurasabiertas}`}
            styleGroup="d-flex align-items-center modal-notificacion "
            styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
            show={showNotificacion}
            handleClose={handleClaseNotificacion}
            handleShow={abrirNotificaciones}
        >

            <div className="table-responsive mt-4">
                <table className="table table-bordered tableGeneral">
                    <thead>
                        <tr>
                            <th scope="col " className="color-madera">
                                #
                            </th>
                            <th scope="col " className="color-madera">
                                N° Factura
                            </th>
                            <th scope="col" className="color-madera">
                                CC / NIT
                            </th>
                            <th scope="col" className="color-madera">
                                NOMBRES
                            </th>
                            <th scope="col" className="color-madera">
                                VALOR
                            </th>
                            <th scope="col" className="color-madera">
                                PENDIENTE DE PAGO
                            </th>

                            <th scope="col" className="color-madera">
                                FECHA LIMITE DE PAGO
                            </th>
                            <th scope="col" className="color-madera">
                                FECHA DE FACTURA
                            </th>
                            <th scope="col" className="color-madera">
                                ESTADO
                            </th>
                        </tr>
                    </thead>
                    {currentPageDataNotificaciones && currentPageDataNotificaciones?.length !== 0 ? (
                        <tbody>
                            {currentPageDataNotificaciones.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <th>{index + 1}</th>
                                        <th>{item?.numeroFactura}</th>
                                        <td>{item?.Cliente?.Persona?.cedula}</td>
                                        <td>
                                            {item?.Cliente?.Persona?.nombre} {item?.Cliente?.Persona?.apellido}
                                        </td>
                                        <td>${Number(item?.total).toLocaleString('es-ES')}</td>
                                        <td>${Number(item?.financiado - item?.sumaAbonos).toLocaleString('es-ES')}</td>
                                        <td>{item?.formattfechaDePago}</td>
                                        <td>{item?.formattedDate}</td>
                                        <td
                                            className={`${item?.formattfechaDePago < fecha ? 'bg-red color-white' : 'bg-yellow color-white'}`}
                                        >
                                            {item?.formattfechaDePago < fecha ? 'Vencida' : 'Abierta'}
                                        </td>

                                    </tr>
                                );
                            })}

                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                {' '}
                                <td colSpan={12} className="text-center">
                                    SIN FACTURA
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>


                {pageCountNoti > 1 && (
                    <PaginadorComponent
                        setCurrentPage={setCurrentPage}
                        pages={pageCountNoti}
                        styleGroup="d-flex justify-content-center pt-4"
                    />
                )}

            </div>

            <div className="d-flex justify-content-end">
                <button type="button" onClick={handleClaseNotificacion} className={`btn ${stylebtn.btn} ${stylebtn.primary} `}>
                    OK
                </button>

            </div>

        </ModalGeneralComponent>
    )
}

export default ModalNotificacionComponent;