import React, { Fragment } from 'react';
import TitleH2Component from '../../components/TitleH2Component';
import HeaderComponent from '../../components/HeaderComponent';
import { Link } from 'react-router-dom';
import stylegeneral from '../../styles/general.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const ConfiguracionPage = () => {

	return (
		<Fragment>
			<HeaderComponent>
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">CONFIGURACION</TitleH2Component>
			</HeaderComponent>

			<div className="conatiner-global pt-3 mb-5">
			
				<div className={`${stylegeneral.contenedor_acordeon} mt-5`}>
					<div className="accordion" id="accordionPanelsStayOpenExample">
						<div className="accordion-item">
							<h2 className="accordion-header" id="panelsStayOpen-headingOne">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#panelsStayOpen-collapseOne"
									aria-expanded="true"
									aria-controls="panelsStayOpen-collapseOne"
								>
									USUARIO
								</button>
							</h2>
							<div
								id="panelsStayOpen-collapseOne"
								className="accordion-collapse collapse show"
								aria-labelledby="panelsStayOpen-headingOne"
							>
								<div className="accordion-body">
									<strong>
										<Link to={'/cambiarclave'}>Cambiar contraseña</Link>
									</strong>
								</div>
							</div>
						</div>

						<div className="accordion-item">
							<h2 className="accordion-header" id="panelsStayOpen-headingTwo">
								<button
									className="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#panelsStayOpen-collapseTwo"
									aria-expanded="false"
									aria-controls="panelsStayOpen-collapseTwo"
								>
									CATEGORIA PRODUCTO
								</button>
							</h2>
							<div
								id="panelsStayOpen-collapseTwo"
								className="accordion-collapse collapse"
								aria-labelledby="panelsStayOpen-headingTwo"
							>
								<div className="accordion-body">
									<div>
										<strong>
											<Link to={'/categoria'}>Clase</Link>
										</strong>
									</div>

									<div>
										<strong>
											<Link to={'/configurartipo'}>Tipo</Link>
										</strong>
									</div>
								</div>
							</div>
						</div>


						<div className="accordion-item">
							<h2 className="accordion-header" id="panelsStayOpen-headingThree">
								<button
									className="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#panelsStayOpen-collapseThree"
									aria-expanded="false"
									aria-controls="panelsStayOpen-collapseThree"
								>
									CLIENTES
								</button>
							</h2>
							<div
								id="panelsStayOpen-collapseThree"
								className="accordion-collapse collapse"
								aria-labelledby="panelsStayOpen-headingThree"
							>
								<div className="accordion-body">
									<div>
										<strong>
											<Link to={'/clientes'}>Ir a cliente</Link>
										</strong>
									</div>
								</div>
							</div>
						</div>
					

					</div>
				</div>
			</div>
		</Fragment>
	);
};
export default ConfiguracionPage;
