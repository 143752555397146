
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';

const useCheckTokenExpiration = () => {
   // const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem('token'); // Asegúrate de obtener el token desde donde lo almacenes

        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // en segundos
            if (decodedToken.exp < currentTime) {
                // Si el token ha expirado
                sessionStorage.removeItem('token'); // Limpia el token
                sessionStorage.removeItem(token); // Limpia otros datos de sesión si es necesario
                window.location.href = "/"// Redirige al usuario a la página de inicio de sesión
            }
        }
    }, []);
};

export default useCheckTokenExpiration;