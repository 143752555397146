export function scrollToTop() {
	const duration = 100; // duración de la animación en milisegundos
	const distance = window.scrollY; // distancia a recorrer, en este caso hasta el inicio de la página
	const increment = distance / (duration / 10); // cantidad de pixeles que se desplaza en cada iteración
	let currentPosition = window.scrollY;
	setTimeout(() => {
		const scrollInterval = setInterval(() => {
			currentPosition -= increment;
			if (currentPosition <= 0) {
				clearInterval(scrollInterval);
				window.scrollTo(0, 0);
			} else {
				window.scrollTo(0, currentPosition);
			}
		}, 5);
		console.log('Scrolling...');
	}, 500);
}
