import { useState, useEffect } from 'react';

// Función para obtener el lunes de una fecha
const obtenerLunes = (fecha) => {
    const diaActual = fecha.getDay();  // 0 es domingo, 1 es lunes, etc.
    const diferencia = diaActual === 0 ? -6 : 1 - diaActual;  // Si es domingo, retrocede 6 días
    const lunes = new Date(fecha);
    lunes.setDate(fecha.getDate() + diferencia);  // Cambia la fecha al lunes
    lunes.setHours(0, 0, 0, 0);  // Establece la hora al inicio del día
    return lunes;
};

const useSumaPrecios = (productos) => {
    const [precios, setPrecios] = useState({ totalSemanaActual: 0, totalSemanaAnterior: 0 });

    useEffect(() => {
        if (productos.length === 0) return;

        const hoy = new Date();

        // Obtener el lunes de la semana actual
        const lunesActual = obtenerLunes(hoy);

        // Obtener el lunes de la semana anterior
        const lunesAnterior = new Date(lunesActual);
        lunesAnterior.setDate(lunesActual.getDate() - 7);

        // Obtener el domingo de la semana anterior (el día antes del lunes actual)
        const domingoAnterior = new Date(lunesActual);
        domingoAnterior.setDate(lunesActual.getDate() - 1);
        domingoAnterior.setHours(23, 59, 59, 999);  // Última hora del domingo anterior

        // Sumar precios de la semana actual
        const totalSemanaActual = productos
            .filter(producto => {
                const fechaProducto = new Date(producto.createdAt);
                return fechaProducto >= lunesActual && fechaProducto <= hoy;
            })
            .reduce((total, producto) => total + producto.precio, 0);

        // Sumar precios de la semana anterior
        const totalSemanaAnterior = productos
            .filter(producto => {
                const fechaProducto = new Date(producto.createdAt);
                return fechaProducto >= lunesAnterior && fechaProducto <= domingoAnterior;
            })
            .reduce((total, producto) => total + producto.precio, 0);

        // Actualizar los totales en el estado
        setPrecios({
            totalSemanaActual,
            totalSemanaAnterior,
        });
    }, [productos]);

    return precios;
};

export default useSumaPrecios;