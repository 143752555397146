import React from "react";
import style from '../styles/alert.module.scss';
import PictureComponent from "./PictureComponent";
import TitleH4Component from "./TitleH4Component";
const AlertComponent = (props) => {
    const styleGroup = props.styleGroup;
    const mensaje = props.mensaje;
    const activaalert = props.activaalert;
    return (
        <div className={`${style.container_alert} ${styleGroup} ${activaalert ? style.active:''}`}>
            <PictureComponent src="/img/imgtodobien.png" alt="logo" styleGroup={`w-auto ${style.logo}`} />
            <TitleH4Component styleGroup="fw600 WorkSans">{mensaje}</TitleH4Component>

        </div>
    )
}

export default AlertComponent;