import React from 'react';
import styleform from '../styles/form.module.scss';
const InputComponent = (props) => {
	const { value, onChange, styleGroup, label, name, type, id, htmlFor, styleLabel, placeholder, disabled, search, styleinput } = props;
	return (
		<div className={`${styleform.input_fuera} ${styleGroup}`}>
			{label ? (
				<label htmlFor={htmlFor} className={`${styleLabel}`}>
					{label}
				</label>
			) : null}
			<input
				type={type}
				id={id}
				name={name}
				className={`${!search && 'form-control'} ${styleinput}`}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				disabled={disabled}
			/>
		</div>
	);
};

export default InputComponent;
