export const DatosUrlSeccion = [
	{
		id: 1,
		name: 'Inicio',
		url: '/home',
		seccion: 1,
		principal: true,
	},
	{
		id: 2,
		name: 'Inventario',
		url: '/inventario',
		seccion: 2,
		principal: true,
	},
	{
		id: 3,
		name: 'Caja menor',
		url: '/caja-menor',
		seccion: 3,
		principal: true,
	},
	{
		id: 4,
		name: 'Empleados',
		url: '/empleado',
		seccion: 4,
		principal: true,
	},
	{
		id: 5,
		name: 'Facturas',
		url: '/factura',
		seccion: 5,
		principal: true,
	},
	{
		id: 6,
		name: 'Cotizacion',
		url: '/cotizacion',
		seccion: 6,
		principal: true,
	},
	{
		id: 7,
		name: 'detalleempleado',
		url: '/detalleempleado',
		seccion: 4,
		principal: false,
	},
	{
		id: 8,
		name: 'detallefactura',
		url: '/detallefactura',
		seccion: 5,
		principal: false,
	},
	{
		id: 9,
		name: 'carrito compra',
		url: '/carrito',
		seccion: 1,
		principal: false,
	},
	{
		id: 10,
		name: 'configuracion',
		url: '/configuracion',
		seccion: 0,
		principal: false,
	},
	{
		id: 11,
		name: 'generarfactura',
		url: '/generarfactura',
		seccion: 1,
		principal: false
	}


];
