import React, { useRef, Fragment, useState, useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import style from '../../styles/pdf.module.scss';
import { Link, useParams } from 'react-router-dom';
import { obtenerDetalleFactura } from '../../api/Factura';
import LoaderComponent from '../../components/LoaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import stylebtn from '../../styles/btn.module.scss';
const GenerarfacturaPages = () => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const contentRefs = useRef([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [currentCliente, setCurrentCliente] = useState([]);
    const [currentFactura, setCurrentFactura] = useState([]);
    const [currentFacturaDF, setCurrentFacturaDF] = useState([]);

    const fetchFactura = async () => {
        setLoading(true);
        try {
            const factura = await obtenerDetalleFactura(id);
            setCurrentFactura(factura?.data);
            setCurrentCliente(factura?.data?.Cliente?.Persona)
            /*const formattedData = (factura?.data?.DetalleFacturas || []).map((item) => ({
                ...item,

                formattedDate: new Date(item.createdAt).toLocaleDateString(), // Formatea la fecha sin usar hook
            }));*/


            const mergedProducts = (factura?.data?.DetalleFacturas || []).reduce((acc, current) => {
                // Buscar si ya existe un producto con la misma clase, tipo y dimensión
                const existingProduct = acc.find(item =>
                    item?.Producto?.Clase?.name === current.Producto.Clase?.name &&
                    item?.Producto?.Tipo?.name === current.Producto.Tipo?.name &&
                    item?.Producto?.dimension === current?.Producto?.dimension
                );

                if (existingProduct) {
                    // Si existe, sumamos la cantidad y recalculamos el precio total
                    existingProduct.cantidad += current.cantidad;
                    existingProduct.cantidadporentragar += current.cantidadporentragar;
                    existingProduct.valorTotalPorProducto = Number(existingProduct.valorTotalPorProducto) + Number(current.valorTotalPorProducto);

                } else {
                    // Si no existe, lo añadimos al array acumulador
                    acc.push({ ...current });
                }

                return acc;
            }, []);

            const partitionArray = (array, size) => {
                const partitioned = [];
                for (let i = 0; i < array.length; i += size) {
                    partitioned.push(array.slice(i, i + size));
                }
                return partitioned;
            };

            const partitionedProducts = partitionArray(mergedProducts, 7);
            setCurrentFacturaDF(partitionedProducts);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCotizacion = async () => {
        setLoading(true);
        try {
            const facturadetalle = await JSON.parse(localStorage.getItem('cotizacion')) || [];
            const cliente = await JSON.parse(localStorage.getItem('cliente')) || [];
            const factura = await JSON.parse(localStorage.getItem('factura')) || [];
            setCurrentFactura(factura);
            setCurrentCliente(cliente)
          console.log('factura de la cotizacion', factura)
            const mergedProducts = (facturadetalle || []).reduce((acc, current) => {
                // Buscar si ya existe un producto con la misma clase, tipo y dimensión
                const existingProduct = acc.find(item =>
                    item?.clase === current.clase &&
                    item?.tipo === current.tipo &&
                    item?.dimension === current?.dimension
                );

                if (existingProduct) {
                    // Si existe, sumamos la cantidad y recalculamos el precio total
                    existingProduct.cantidad += current.cantidad;
                    existingProduct.cantidadporentragar += current.cantidadporentragar;
                    existingProduct.valorTotalPorProducto = Number(existingProduct.valorTotalPorProducto) + Number(current.valorTotalPorProducto);

                } else {
                    // Si no existe, lo añadimos al array acumulador
                    acc.push({ ...current });
                }

                return acc;
            }, []);

            const partitionArray = (array, size) => {
                const partitioned = [];
                for (let i = 0; i < array.length; i += size) {
                    partitioned.push(array.slice(i, i + size));
                }
                return partitioned;
            };

            const partitionedProducts = partitionArray(mergedProducts, 7);
            setCurrentFacturaDF(partitionedProducts);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchFactura();
        }else{
            fetchCotizacion() 
        }
    }, [id]);



    const handleGeneratePdf = () => {
        // Crear un div temporal para combinar todo el contenido
        const element = document.createElement('div');
        contentRefs.current.forEach((ref) => {
            if (ref) {
                element.appendChild(ref.cloneNode(true)); // Clona y agrega cada ref al div temporal
            }
        });

        // Generar el PDF y convertirlo a Blob
        html2pdf()
            .from(element)
            .toPdf()
            .output('blob')
            .then((pdfBlob) => {
                // Crear una URL para mostrar el PDF
                const url = URL.createObjectURL(pdfBlob);
                setPdfUrl(url);
            });
    };

    useEffect(() => {
        if (currentFacturaDF.length > 0) {
            setTimeout(() => {
                handleGeneratePdf();
            }, 500);
        }

    }, [currentFacturaDF])

    return (
        <Fragment>
            {loading ? (
                <LoaderComponent styleGroup="mt-5" />
            ) : <div>
                <h1 className='text-center'>Generando factura .....</h1>
                {currentFacturaDF.map((group, index) => {
                    return (
                        <div ref={el => (contentRefs.current[index] = el)} className={`${index > 0 ? `${style.nuevapagina}` : ''} ${style.container_pdf} `} key={index}>
                            <TitleH2Component styleGroup=" color-dark fw700 roboto mb-2 text-center">COMPRAVENTA MADERAS EL PACA</TitleH2Component>
                            <div className='d-flex align-items-start justify-content-between fw700'>
                                <div>
                                    FECHA DE FACTURA
                                    <div className={style.caja_fecha}>{currentFactura?.createdAt ? new Date(currentFactura?.createdAt).toLocaleDateString('es-ES') : currentFactura?.fechafactura} </div>
                                </div>
                              
                                <div className='text-center'>Cra 5 No. 27-07 <br /> Barrio San Roque <br /> Moñitos Córdoba <br /> 3012222358</div>

                                <div>
                                    FACTURA DE VENTA
                                    <div className={style.caja_fecha}>N° {currentFactura?.numeroFactura}</div>
                                </div>
                            </div>
                            <div className={`${style.card_cliente}`}>
                                <div className={`${style.card_documento}`}>
                                    <p >
                                        N Documento: {currentCliente?.cedula}
                                    </p>
                                    <div className={`${style.linea}`}></div>
                                    <p >
                                        Cilente: {currentCliente?.nombre} {currentCliente?.apellido}
                                    </p>
                                </div>
                                <div className={`${style.card_direccion}`}>
                                    <p>
                                        Direccion: {currentCliente?.direccion}
                                    </p>

                                </div>
                                <div className={`${style.card_celular}`}>
                                    <p>
                                        Celular: {currentCliente?.celular}
                                    </p>

                                </div>
                            </div>
                            <table className={`${style.styled_table}`}>
                                <thead>
                                    <tr>

                                        <th>PENDIENTE</th>
                                        <th>ENTREGADOS</th>
                                        <th>CANTIDAD</th>
                                        <th>DESCRIPCIÓN</th>
                                        <th>VAL X UNI</th>
                                        <th>TOTAL UNIDS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(group) && group.length > 0 ? (
                                        group.map((item, index) => (
                                            <tr key={index}>
                                                <td className={`${style.empty_row}`}>{item?.pendientePorEntregar}</td>
                                                <td className={`${style.empty_row}`}>{item?.cantidad}</td>
                                                <td className={`${style.empty_row}`}> {Number(item?.pendientePorEntregar) + Number(item?.cantidad)}</td>
                                                <td className={`${style.empty_row}`}>{item?.Producto?.Tipo?.name ?? item?.tipo} de {item?.Producto?.Clase?.name ?? item?.clase} de {item?.Producto?.dimension ?? item?.dimension}</td>
                                                <td className={`${style.empty_row}`}>{Number(item?.Producto?.precioVenta ?? item?.valorPorUnidad).toLocaleString('es-ES')}</td>
                                                <td className={`${style.empty_row}`}>{Number(item?.valorTotalPorProducto).toLocaleString('es-ES')}</td>
                                            </tr>
                                        ))
                                    ) : (<tr>
                                        <td colSpan={6} className={`${style.empty_row}`}>No hay facturas disponibles</td>
                                    </tr>)}
                                </tbody>
                                {Array.isArray(group) && group.length > 0 && <tfoot>

                                    <tr>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1">Subtotal</td>
                                        <td>${Number(currentFactura?.subtotal).toLocaleString('es-ES')}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1">
                                            <div className={`${style.cello}`}>
                                                {currentFactura?.estado == 1 && 'NO PAGA'}
                                                {currentFactura?.estado == 2 && 'PENDIENTE'}
                                                {currentFactura?.estado == 0 && 'PAGA'}
                                                {currentFactura?.estado !== 0 && currentFactura?.estado !== 1 && currentFactura?.estado !== 2 &&   'COTIZACION'}
                                            </div>
                                        </td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1">Descuento</td>
                                        <td>${Number(currentFactura?.descuento).toLocaleString('es-ES')}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1">Financiado</td>
                                        <td>${Number(currentFactura?.financiado).toLocaleString('es-ES')}</td>
                                    </tr>

                                    <tr>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1">Transporte</td>
                                        <td>${Number(currentFactura?.transporte).toLocaleString('es-ES')}</td>
                                    </tr>

                                    {currentFactura?.estado !== 3 && <tr>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1">Cancelo</td>
                                        <td>${Number(currentFactura?.estado === 0 ? (Number(currentFactura?.total) + Number(currentFactura?.transporte)) : ((Number(currentFactura?.total) + Number(currentFactura?.transporte)) - currentFactura?.financiado)).toLocaleString('es-ES')}</td>
                                    </tr>}
                                    <tr>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1"></td>
                                        <td colSpan="1">Total</td>
                                        <td>${Number(Number(currentFactura?.total) + Number(currentFactura?.transporte)).toLocaleString('es-ES')}</td>
                                    </tr>
                                </tfoot>}
                            </table>

                        </div>
                    )

                })}


                {pdfUrl ? (
                    <div className={`${style.viewpdf}`}>
                        <embed
                            src={pdfUrl}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                            style={{ border: '1px solid black' }}
                        />
                        <Link to="/factura" className={`${style.btn_volver} ${stylebtn.btn} ${stylebtn.primary}`}>Ir a Facturas</Link>
                    </div>
                ) : null}
            </div>}

        </Fragment>
    )
}
export default GenerarfacturaPages;