import React from "react";
import style from '../styles/card.module.scss';
import TitleH1Component from "./TitleH1Component";
import TitleH4Component from "./TitleH4Component";
import TitleH2Component from "./TitleH2Component";
import { Link } from "react-router-dom";
import { scrollToTop } from "./GlobalFunction";
import ParrafoComponent from "./ParrafoComponent";
const CardProductoComponent = (props) => {
    const { cardpuesto } = props;
    const name = props.item.name;
    const puestosCount = props.item.puestosCount;
    const totalCantidad = props.item.totalCantidad;
    const id = props.item.id;
    const numeroP = props.item.numero;
    const Productos = props.item.Productos;
    const selectedClase = props.selectedClase;
    const selectedTipo = props.selectedTipo;
    const selectedOptionDimension = props.selectedOptionDimension;


    const scroll = () => {
        scrollToTop()
    }

    return (
        <div className={`${style.fondo_card} p-4`} >
            {cardpuesto ? <Link to={`/producto/${id}`} className={`${style.link_card}`}></Link> :
                <Link to={`/puesto/${id}?idClase=${selectedClase}&idTipo=${selectedTipo}&dimension=${selectedOptionDimension}`} className={`${style.link_card}`} onClick={scroll}></Link>}
            {cardpuesto ? <TitleH2Component styleGroup="color-white fw700 roboto mb-2">Puesto {numeroP}</TitleH2Component> :
                <TitleH1Component styleGroup="color-white fw700 roboto mb-2">B{name}</TitleH1Component>
            }
            <ParrafoComponent styleGroup="color-white fw700 WorkSans mb-0">{cardpuesto ? `Cantidad: ${totalCantidad ?? 0}` : `Puestos: ${puestosCount ?? 0}`}</ParrafoComponent>
            {cardpuesto && Productos?.length !== 0 ? <ul className={`${style.list_product} color-white fw700 WorkSans `}>
                {Productos?.map((item, index) => {
                    return (
                        <li key={index}>
                           {item?.Tipo?.name} - {item?.Clase?.name}    
                        </li>
                    )
                })}
            </ul> : null}
        </div>
    )
}
export default CardProductoComponent;