import React from "react";
import style from '../styles/loader.module.scss';
const LoaderComponent = (props) => {
    return (
        <div className={`${style.loading_container} ${props.styleGruop}`}>
            <div className={`${style.loader}`}></div>
        </div>
    )
}

export default LoaderComponent;