import React, { useEffect, useState } from "react";
import Select from 'react-select';
const SelectComponent = (props) => {

    const [selectedOption, setSelectedOption] = useState(null);
    // Simulación de cargar un valor por defecto (por ejemplo, cuando se está editando un formulario)
    useEffect(() => {
        if (props.defaultValue) {
            const defaultOption = props.options.find(option => option.label === props.defaultValue);
            props.capOpcions(defaultOption?.value);
            setSelectedOption(defaultOption);
        }
    }, [props.defaultValue, props.options]);


    const handleOpcionChange = (electedOption) => {
        setSelectedOption(electedOption);
        props.capOpcions(electedOption?.value);
    };

    return (
        <div className={`${props.styleGroup}`}>
            {props.label ? <label htmlFor={props.htmlFor} className={`${props.styleLabel}`}>{props.label} </label> : null}
            <Select
                options={props.options}
                placeholder={props.customPlaceholder}
                onChange={handleOpcionChange}
                value={selectedOption}
                isDisabled={props.isDisabled}
                isClearable={props.isClearable}
            />
        </div>

    )
}

export default SelectComponent;