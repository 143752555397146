import React, { Fragment, useEffect, useState } from "react";
import stylebtn from '../../styles/btn.module.scss';
import TitleH4Component from "../../components/TitleH4Component";
import ParrafoComponent from "../../components/ParrafoComponent";
import HeaderComponent from "../../components/HeaderComponent";
import SelectComponent from "../../components/SelectComponent";
import CardProductoComponent from "../../components/CardProductoComponent";
import TitleH2Component from "../../components/TitleH2Component";
import PaginadorComponent from "../../components/PaginadorComponent";
import LoaderComponent from "../../components/LoaderComponent";
import AlertComponent from "../../components/AlertComponent";
const BasePage = () => {
    const [alert, setAlert] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [data] = useState([...Array(100).keys()]); // Simulando datos, un array de 100 elementos
    const itemsPerPage = 12;
    const pageCount = Math.ceil(data.length / itemsPerPage);

    const currentPageDataBloque = data.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ];

    const optionsP = [
        { value: 'chocolate', label: 'Chocolate Prueba' },
        { value: 'strawberry', label: 'Strawberry Prueba' },
        { value: 'vanilla', label: 'Vanilla Prueba' }
    ];

    useEffect(() => {
        setAlert(true);
        setTimeout(() => {
            setAlert(false)
        }, 3000);
    }, [])
    return (
        <Fragment>
            <HeaderComponent styleGroup="">
                <TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">BLOQUES</TitleH2Component>
                <button type="button" className={`${stylebtn.btn} ${stylebtn.primary} `}>Primario</button>
            </HeaderComponent>
            <div className="conatiner-global pt-2">
                <TitleH4Component styleGroup=" color-dark fw400 WorkSans">Puestos: 12</TitleH4Component>
                <ParrafoComponent styleGroup=" color-dark fw400 WorkSans">Textos mensajes</ParrafoComponent>
                <ParrafoComponent styleGroup=" color-danger fw400 WorkSans">Textos mensajes</ParrafoComponent>
                <button type="button" className={`${stylebtn.btn} ${stylebtn.primary} mb-3`}>Primario</button>
                <button type="button" className={`${stylebtn.btn} ${stylebtn.secundary} mb-3 `}>segundario</button>
                <button type="button" className={`${stylebtn.btn} ${stylebtn.danger} `}>danger</button>
                <AlertComponent styleGroup="bg-soccess color-white" mensaje="Creado con éxito" activaalert={alert} />
                <LoaderComponent />
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <SelectComponent options={options} />
                        </div>

                        <div className="col-4">
                            <SelectComponent options={optionsP} />
                        </div>

                    </div>

                    <div className="row mt-5 gy-4">
                        {currentPageDataBloque.map(() => {
                            return (
                                <div className="col-lg-4 col-xl-3 col-6  ">
                                    <CardProductoComponent />
                                </div>
                            )
                        })}
                    </div>

                    <PaginadorComponent
                        setCurrentPage={setCurrentPage}
                        pages={pageCount}
                        styleGroup='d-flex justify-content-center pt-4' />

                    <div className="row mt-5 gy-4">
                        {currentPageDataBloque.map(() => {
                            return (
                                <div className="col-lg-4 col-xl-3 col-6  ">
                                    <CardProductoComponent cardpuesto />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Fragment>

    )
}
export default BasePage;