import React, { Fragment } from "react";

const PictureComponent = (props) => {
    const { src, alt, styleGroup } = props;


    const isValidImage = (file) => {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;
        return allowedExtensions.test(file.name);
    };
    return (
        <Fragment>
            {isValidImage === '.png' ? (
                <picture >
                    <source src={src + '?format=webp'} type="image/webp" />
                    <source src={src + '?format=png'} type="image/png" />
                    <img className={`w-100 h_auto_img ${styleGroup ? styleGroup : ''}`} src={src} alt={alt} height="100" width="100" />
                </picture>
            ) :
                <picture >
                    <source src={src + '?format=webp'} type="image/webp" />
                    <source src={src + '?format=jpg'} type="image/jpeg" />
                    <img className={`w-100 h_auto_img ${styleGroup ? styleGroup : ''}`} src={src} alt={alt} height="100" width="100" />
                </picture>
            }
        </Fragment>

    )
}

export default PictureComponent;