import React, { Fragment, useEffect, useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import SelectComponent from '../../components/SelectComponent';
import stylebtn from '../../styles/btn.module.scss';
import InputComponent from '../../components/InputComponent';
import stylegeneral from '../../styles/general.module.scss';
import TitleH4Component from '../../components/TitleH4Component';
import { getClases } from '../../api/Clase';
import { getTipos } from '../../api/Tipo';
import {
	FiltrarabonosEmbleados,
	Filtrarabonosfactura,
	Filtrarcajamenor,
	Filtrarinventario,
	Filtrarnomina,
} from '../../api/Filters';
import LoaderComponent from '../../components/LoaderComponent';

const InventarioPage = () => {
	const [dataInvebtario, setDataInventario] = useState([]);
	const [optionsclass, setOptionsClass] = useState([]);
	const [selectedOptionClass, setSelectedOptionClass] = useState(null);
	const [optionstype, setOptionstype] = useState([]);
	const [selectedOptionType, setSelectedOptionType] = useState(null);
	const [totalVentas, setTotalVentas] = useState(0);
	const [totalCompra, setTotalCompra] = useState(0);
	const [descuento, setDescuento] = useState(0);
	const [financiado, setFinanciado] = useState(0);
	const [totalcajamenor, setTotalCajaMenor] = useState(0);
	const [totalabonosf, setTotalabonosf] = useState(0);
	const [totalabonosEmpleados, setTotalabonosEmpleados] = useState(0);
	const [loading, setLoading] = useState(false);
	const [dataNomina, setDataNomina] = useState([]);
	const [cantidadFacturas, setCantidadFacturas] = useState(0);
	const [formDataFecha, setFormDataFecha] = useState({
		fechaI: '',
		fechaFin: '',
	});

	let horamax = '16:30';
	let minamax = 510;
	let precioHoraExtra = 7000;

	// Manejar el cambio en el input
	const handleInputChangeFecha = (event) => {
		const { name, value } = event.target;
		setFormDataFecha({
			...formDataFecha,
			[name]: value,
		});
	};

	useEffect(() => {
		getClases()
			.then((response) => {
				const formattedOptions = response?.data?.map((item) => ({
					value: item.id,
					label: item.name,
				}));
				setOptionsClass(formattedOptions);
			})
			.catch((error) => {
				console.log(error.response);
			});
	}, []);

	useEffect(() => {
		getTipos()
			.then((response) => {
				const formattedOptions = response?.data?.map((item) => ({
					value: item.id,
					label: item.name,
				}));
				setOptionstype(formattedOptions);
			})
			.catch((error) => {
				console.log(error.response);
			});
	}, []);

	useEffect(() => {
		// Capturar la fecha actual
		const today = new Date();
		const currentDate = formatDate(today); // Formatear la fecha actual

		// Capturar la fecha del día siguiente
		const tomorrow = new Date(today);
		tomorrow.setDate(today.getDate() + 1);
		const nextDate = formatDate(tomorrow); // Formatear la fecha del día siguiente

		setFormDataFecha({
			fechaI: currentDate,
			fechaFin: nextDate,
		});
	}, []);

	// Función para formatear la fecha en 'YYYY-MM-DD'
	const formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes de 2 dígitos
		const day = String(date.getDate()).padStart(2, '0'); // Día de 2 dígitos
		return `${year}-${month}-${day}`;
	};

	// metodo para crear el inventario
	const crearInventario = async () => {
		setLoading(true);
		Filtrarinventario({
			idClase: selectedOptionClass ?? '',
			idTipo: selectedOptionType ?? '',
			fechaInicio: formDataFecha.fechaI ?? '',
			fechaFin: formDataFecha.fechaFin ?? '',
		})
			.then((response) => {
				setDataInventario(response);

				let facturasConDetalles = 0;
				// Sumar los totales de los diferentes campos solo en las facturas que tienen detalles
				const { totalVenta, descuento, financiado, totalCompra } = response?.data?.reduce(
					(acc, factura) => {
						// Verificar si la factura tiene detalles y, en ese caso, sumar cada uno de los valores
						if (factura.DetalleFacturas && factura.DetalleFacturas.length > 0) {
							// Primero, sumar los valores de la factura (si aplica)
							facturasConDetalles++;
							acc.descuento += Number(factura?.descuento || 0);
							acc.financiado += Number(factura?.financiado || 0);

							// Sumar los valores de todos los detalles dentro de esta factura
							factura.DetalleFacturas.forEach((detalle) => {
								acc.totalVenta += Number(detalle?.valorTotalPorProducto || 0);
								acc.totalCompra += Number(
									(detalle?.cantidad + detalle?.pendientePorEntregar) * detalle.Producto.precioCompra || 0
								);
							});
						}
						return acc;
					},
					{ totalVenta: 0, descuento: 0, financiado: 0, totalCompra: 0 } // Valores iniciales
				);

				// Calculamos el descuento total
				setDescuento(descuento);
				setFinanciado(financiado);
				setTotalCompra(totalCompra);
				setTotalVentas(totalVenta);
				setCantidadFacturas(facturasConDetalles);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
			});

		try {
			const res = await Filtrarcajamenor({
				startDate: formDataFecha.fechaI ?? '',
				endDate: formDataFecha.fechaFin ?? '',
			});

			const resnomina = await Filtrarnomina({
				startDate: formDataFecha.fechaI ?? '',
				endDate: formDataFecha.fechaFin ?? '',
			});
			setDataNomina(resnomina?.data);

			const resabonosEmpleados = await FiltrarabonosEmbleados({
				startDate: formDataFecha.fechaI ?? '',
				endDate: formDataFecha.fechaFin ?? '',
			});

			const resabonosF = await Filtrarabonosfactura({
				startDate: formDataFecha.fechaI ?? '',
				endDate: formDataFecha.fechaFin ?? '',
			});
			console.log('abonos a la factura', resabonosF);

			if (res?.data?.length === 0) {
				setTotalCajaMenor(0);
			} else {
				// calculamos la suma de las cajas menores
				const totalencajamenor = res?.data?.reduce((acc, item) => acc + Number(item?.precio), 0);
				setTotalCajaMenor(totalencajamenor);
			}

			// calculo de los abonos de los empleados
			if (resabonosEmpleados?.data?.length === 0) {
				setTotalabonosEmpleados(0);
			} else {
				const totalabonosempleado = resabonosEmpleados?.data?.reduce((acc, item) => acc + Number(item?.monto), 0);
				setTotalabonosEmpleados(totalabonosempleado);
			}

			// calculo de los abonos de las factura
			if (resabonosF?.data?.length === 0) {
				setTotalabonosf(0);
			} else {
				const totalabonosfactura = resabonosF?.data?.reduce((acc, item) => acc + Number(item?.monto), 0);
				setTotalabonosf(totalabonosfactura);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// este metodo hace que las cosas cambien de estado pordefecto a penas ingreses a la vista
	useEffect(() => {}, [selectedOptionClass, selectedOptionType]);

	// Función para convertir las horas (HH:MM) en minutos
	const convertirHoraEnMinutos = (hora) => {
		const [hh, mm] = hora.split(':');
		return parseInt(hh) * 60 + parseInt(mm);
	};

	// Calcular las horas trabajadas
	const calcularHorasTrabajadas = (horaEntrada, horaSalida, inicioReceso, finReceso) => {
		const minutosEntrada = convertirHoraEnMinutos(horaEntrada);
		let minutosSalida;
		const minutosInicioReceso = convertirHoraEnMinutos(inicioReceso);
		const minutosFinReceso = convertirHoraEnMinutos(finReceso);
		if (horaSalida > horamax) {
			minutosSalida = convertirHoraEnMinutos(horamax);
		} else {
			minutosSalida = convertirHoraEnMinutos(horaSalida);
		}
		// Cálculo total: horas trabajadas sin el tiempo de receso
		const minutosTrabajados = minutosSalida - minutosEntrada - (minutosFinReceso - minutosInicioReceso);

		// Convertir minutos trabajados en horas y minutos
		const horas = Math.floor(minutosTrabajados / 60);
		const minutos = minutosTrabajados % 60;
		const totalMinutos = horas * 60 + minutos;

		return { horas, minutos, totalMinutos };
	};

	// Calcular las horas extras trabajadas
	const calcularHorasExtrasTrabajadas = (horaSalida) => {
		const minutosmax = convertirHoraEnMinutos(horamax);
		let minutosSalida = convertirHoraEnMinutos(horaSalida);
		let minutosTrabajados;

		if (horaSalida > horamax) {
			// Cálculo total: horas trabajadas sin el tiempo de receso
			minutosTrabajados = minutosSalida - minutosmax;
		} else {
			minutosTrabajados = 0;
		}
		// Convertir minutos trabajados en horas y minutos
		const horas = Math.floor(minutosTrabajados / 60);
		const minutos = minutosTrabajados % 60;
		const totalMinutos = horas * 60 + minutos;

		return { horas, minutos, totalMinutos };
	};

	const calcularTotales = (data) => {
		let totalPagos = 0;
		let totalAdelantos = 0;

		data.forEach((item) => {
			const horasTrabajadas = calcularHorasTrabajadas(
				item?.horaEntrada,
				item?.horaSalida,
				item?.inicioReceso,
				item?.finReceso
			).totalMinutos;
			const horasExtrasTrabajadas = calcularHorasExtrasTrabajadas(item?.horaSalida).totalMinutos;
			const pago = Math.round(
				Number((item?.Empleado?.salario / minamax) * horasTrabajadas) + (precioHoraExtra / 60) * horasExtrasTrabajadas
			);

			if (item?.estadoPago == true || item?.estadoPago == 1) {
				totalPagos += pago;
			}
			if (
				new Date(item.createdAt) >= new Date(formDataFecha.fechaI) &&
				new Date(item.createdAt) <= new Date(formDataFecha.fechaFin)
			) {
				totalAdelantos += item?.adelanto ? item?.adelanto : 0; // Asegúrate de que adelanto no sea null
			}
		});

		return { totalPagos, totalAdelantos };
	};

	const { totalPagos, totalAdelantos } = calcularTotales(dataNomina);

	return (
		<Fragment>
			{/** codigo Html * */}
			<HeaderComponent styleGroup="">
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">INVENTARIO</TitleH2Component>
			</HeaderComponent>

			<div className="conatiner-global pt-3 mb-5">
				<div className="container">
					<div className="row gy-4">
						<div className="col-12 col-md-6 col-xl-3">
							<SelectComponent
								styleLabel="color-madera fw600"
								label="Clase de madera"
								customPlaceholder="Selecione la clase "
								options={optionsclass}
								name="clase"
								value={selectedOptionClass}
								capOpcions={setSelectedOptionClass}
								isClearable
							/>
						</div>
						<div className="col-12 col-md-6 col-xl-3">
							<SelectComponent
								styleLabel="color-madera"
								label="Tipo de madera"
								customPlaceholder="Selecione la clase "
								options={optionstype}
								name="tipo"
								value={selectedOptionType}
								capOpcions={setSelectedOptionType}
								isClearable
							/>
						</div>
						<div className="col-12 col-md-6 col-xl-2">
							<InputComponent
								placeholder="Ingrese eje B1..B2.."
								label="Fecha Inicio:"
								styleLabel="fw600 WorkSans"
								styleGroup=""
								htmlFor="bloque"
								type="date"
								name="fechaI"
								value={formDataFecha.fechaI}
								onChange={handleInputChangeFecha}
							/>
						</div>
						<div className="col-12 col-md-6 col-xl-2">
							<InputComponent
								placeholder="Ingrese eje B1..B2.."
								label="Fecha Final:"
								styleLabel="fw600 WorkSans"
								styleGroup=""
								htmlFor="bloque"
								type="date"
								name="fechaFin"
								value={formDataFecha.fechaFin}
								onChange={handleInputChangeFecha}
							/>
						</div>

						<div className="col-12 col-md-6 col-xl-2 d-flex justify-content-start">
							<button type="button" onClick={crearInventario} className={`${stylebtn.btn} ${stylebtn.primary}  mt-4`}>
								CALCULAR
							</button>
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-center">
					{loading ? (
						<LoaderComponent styleGroup="mt-5" />
					) : dataInvebtario?.length !== 0 ? (
						<div className={`${stylegeneral.cuadro_inventario} mt-5 py-2 px-1 w-100`}>
							<TitleH4Component
								styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton"
								style={{ backgroundColor: '#F5DEB3' }}
							>
								<span>Ventas realizadas</span> <span># {cantidadFacturas}</span>
							</TitleH4Component>

							<TitleH4Component styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton">
								<span>Inversion de compra</span> <span> ${Number(totalCompra).toLocaleString('es-ES')}</span>
							</TitleH4Component>

							<TitleH4Component
								styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton"
								style={{ backgroundColor: '#F5DEB3' }}
							>
								<span>Valor de venta</span> <span> ${Number(totalVentas).toLocaleString('es-ES')}</span>
							</TitleH4Component>

							<TitleH4Component styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton">
								<span>Abonos de los emplados</span> <span>${Number(totalabonosEmpleados).toLocaleString('es-ES')}</span>
							</TitleH4Component>

							<TitleH4Component
								style={{ backgroundColor: '#F5DEB3' }}
								styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton"
							>
								<span>Adelantos nomina</span>{' '}
								<span>
									$
									{Number(
										totalAdelantos - totalabonosEmpleados < 0 ? 0 : totalAdelantos - totalabonosEmpleados
									).toLocaleString('es-ES')}
								</span>
							</TitleH4Component>

							<TitleH4Component styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton">
								<span>Total financiado</span>{' '}
								<span>
									${Number(financiado - totalabonosf < 0 ? 0 : financiado - totalabonosf).toLocaleString('es-ES')}
								</span>
							</TitleH4Component>

							<TitleH4Component
								style={{ backgroundColor: '#F5DEB3' }}
								styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton"
							>
								<span>Descuento aplicado</span> <span>${Number(descuento).toLocaleString('es-ES')}</span>
							</TitleH4Component>

							<TitleH4Component styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton">
								<span>Caja menor</span> <span> ${Number(totalcajamenor).toLocaleString('es-ES')}</span>
							</TitleH4Component>

							<TitleH4Component
								style={{ backgroundColor: '#F5DEB3' }}
								styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton"
							>
								<span>Pagos de nomina</span> <span> ${Number(totalPagos).toLocaleString('es-ES')}</span>
							</TitleH4Component>

							<TitleH4Component styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton">
								<span>Margen de ganacia</span>{' '}
								<span> ${Number(totalVentas - totalCompra).toLocaleString('es-ES')}</span>
							</TitleH4Component>

							<TitleH4Component
								style={{ backgroundColor: '#F5DEB3' }}
								styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton"
							>
								<span>Dinero en caja</span>{' '}
								<span>
									{' '}
									$
									{Number(
										totalVentas +
											totalabonosEmpleados +
											totalabonosf -
											(descuento + financiado + totalcajamenor + totalAdelantos + totalPagos)
									).toLocaleString('es-ES')}
								</span>
							</TitleH4Component>
							<TitleH4Component styleGroup=" color-dark fw400 WorkSans fw700 d-flex justify-content-between align-items-center px-1 border-botton">
								<span>Total utilidades</span>{' '}
								<span>
									{' '}
									$
									{Number(
										totalVentas +
											totalabonosEmpleados +
											totalabonosf -
											(descuento + totalCompra + totalcajamenor + financiado + totalAdelantos + totalPagos)
									).toLocaleString('es-ES')}
								</span>
							</TitleH4Component>
						</div>
					) : (
						<TitleH2Component styleGroup="box-text color-dark fw700 roboto my-4 ">SIN VENTAS AUN</TitleH2Component>
					)}
				</div>
			</div>
		</Fragment>
	);
};
export default InventarioPage;
