import instance from "../config";


// metodo para trae todo los bloques

const getBloques = async (params) => {

    try {
        const { data } = await instance.get('/api/bloque',{params});
        return data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

// Metodo crear un Bloque 

const PostBloques = async (name) => {
    const { data } = await instance.post('/api/bloque', { name, });
    return data;
}

export const getBloqueByID = async (id,params) => {
  
    try {
        const response = await instance.get(`/api/bloque/${id}`,{params});
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

// Metodo editar Bloque

const EditarBloque = async (id,name) => {
    try {
        const response = await instance.put(`/api/bloque/${id}`,{name});
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// Metodo de eliminar el bloque

const EliminarBloque = async (id) => {
    try {
        const response = await instance.delete(`/api/bloque/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return  error.response.data;
    }
}


// crear un puesto a su blouqe 

const PostPuesto = async (bloqueId, numero) => {
    const response = await instance.post('/api/puesto', { bloqueId, numero, });
    return response.data;

}

// Metodo de eliminar el puesto

const EliminarPuesto = async (id) => {
    try {
        const response = await instance.delete(`/api/puesto/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return  error.response.data;
    }
}

// consultar un puesto
export const getPuestoByID = async (id) => {
    try {
        const response = await instance.get(`/api/puesto/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}


// crear un producto

// crear un puesto a su blouqe 

const PostProducto = async (idPuesto,idClase,idTipo,dimension, cantidad, precioCompra, precioVenta) => {
   
    try {
        const response = await instance.post('/api/producto', {idPuesto,idClase,idTipo,dimension, cantidad, precioCompra, precioVenta});
        return response.data;
    } catch (error) {
        return error.response.data;

    }
}

// Metodo eliminar un producto 

const EliminarProducto = async (id) => {
    try {
        const response = await instance.delete(`/api/producto/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}

// Metodo para editar un producto 
const EditarProducto = async (id,idClase,idTipo,dimension, cantidad, precioCompra, precioVenta) => {
    try {
        const response = await instance.put(`/api/producto/${id}`,{idClase,idTipo,dimension, cantidad, precioCompra, precioVenta});
        return response.data;
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}


// Metodo para buscar a  un cliente 

const BuscarCliente = async (cedula)=>{
    try {
        const response = await instance.get(`/api/persona/${cedula}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

// crear un puesto a su blouqe 

const PostCliente = async (personaId) => {
   
    try {
        const response = await instance.post('/api/cliente', {personaId});
        return response.data;
    } catch (error) {
        return error.response.data;

    }
}

// Metodo para actualizar los productos si la factura es paga
const PostActualizarventa = async (dataProductos) => {

	try {
		const response = await instance.post('/api/productos', { dataProductos });
		return response.data;
	} catch (error) {
		return error.response.data;

	}
}

export {
    getBloques,
    PostBloques,
    PostPuesto,
    EditarBloque,
    EliminarBloque,
    EliminarPuesto,
    PostProducto,
    EliminarProducto,
    EditarProducto,
    BuscarCliente,
    PostCliente,
    PostActualizarventa,
}