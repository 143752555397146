import React from "react";
import PictureComponent from "./PictureComponent";
import style from '../styles/header.module.scss';
import TitleH2Component from "./TitleH2Component";
const HeaderComponent = (props) => {
    return (
        <header className={`${style.header} conatiner-global ${props.styleGroup} pb-2`}>
            <div>{props.children}</div>
            <div className={`${style.header_logo}`}>
                <TitleH2Component styleGroup="box-text color-dark fw700 roboto">Maderas el paca</TitleH2Component>
                <PictureComponent src="/img/logo.png" alt="logo" styleGroup={`w-auto ${style.logo}`} />
            </div>
        </header>
    )
}

export default HeaderComponent;