import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import style from '../styles/barralateral.module.scss';
import stylegeneral from '../styles/general.module.scss';
import styleBtn from '../styles/btn.module.scss';
import { Link, useLocation } from "react-router-dom";
import { Icon } from '@iconify/react';
import PictureComponent from "./PictureComponent";
import { DatosUrlSeccion } from "../api/DatosUrlSeccion";
import { scrollToTop } from "./GlobalFunction";
import { Logout } from "../api/Autenticacion";
import { UserContext } from "../context/UserContext";

const BarraLateralComponent = (props) => {
  const { setControlMenu } = props;
  const { numCarrito, } = useContext(UserContext);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [seccionActual, setSeccionActual] = useState(1);
  const [Url, setUrl] = useState('/home');

  const selectRef = useRef();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname])

  // capturamos la url actual
  useEffect(() => {
    DatosUrlSeccion.forEach(view => {
      if (view.url === currentPath) {
        setSeccionActual(view.seccion);
        setUrl(view.url);

      } else {
        // capturamos la url sin id 
        const partesUrlNavegador = currentPath.split('/');
        const urlNavegadorBase = partesUrlNavegador.slice(0, partesUrlNavegador.length - 1).join('/');
        if (view.url === urlNavegadorBase)
          setSeccionActual(view.seccion);
      }
    })

  },);

  const odternerId = () => {
    scrollToTop();
    setControlMenu(false);
  }

  const logout = () => {
    Logout();
    window.location.href = "/"
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setControlMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {

  }, [numCarrito]);


  return (
    <Fragment>

      <div ref={selectRef} className={`${stylegeneral.fondo_img} ${stylegeneral.login_fondo} p-3 d-flex flex-column`}>
        <div className="d-flex justify-content-between mb-5">
          <Link to={'/configuracion'} onClick={odternerId}>
            <PictureComponent src="/img/config.png" alt="logo" styleGroup={`w-auto ${styleBtn.btnLink_img}`} />
          </Link>


          <Link to={'/carrito'} className={`${styleBtn.btnLink} color-plata ${style.containerIconcar}`} onClick={odternerId}>
            <span>{numCarrito}</span>
            <Icon icon="typcn:shopping-cart" />
          </Link>

        </div>

        <nav className={`${style.navbar}`}>
          <ul className="p-0 fw700">
            {DatosUrlSeccion.map((item, index) => {
              return (
                <li key={index} className={`${(seccionActual === item.seccion) ? style.active : ''} WorkSans mb-4 ${item.principal ? '' : 'd-none'}`} onClick={odternerId}><Link to={item.url}>{item.name}</Link></li>
              )
            })}
          </ul>
        </nav>
        <button type="button" onClick={logout} className={`fixclase WorkSans ${styleBtn.btn_desnudo}  ${styleBtn.text} color-white fw700 d-flex  justify-content-center align-items-center`}><Icon icon="hugeicons:logout-04" /> <span className="mb-0 ms-2">Cerrar sesion</span></button>
      </div>
    </Fragment>
  )
}

export default BarraLateralComponent;