import React, { Fragment, useEffect, useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import stylebtn from '../../styles/btn.module.scss';
import { Icon } from '@iconify/react/dist/iconify.js';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';
import InputComponent from '../../components/InputComponent';
import TitleH4Component from '../../components/TitleH4Component';
import { Link } from 'react-router-dom';
import AlertComponent from '../../components/AlertComponent';
import LoaderComponent from '../../components/LoaderComponent';
import ParrafoComponent from '../../components/ParrafoComponent';
import { EditarTipo, EliminarTipo, getTipoByID, getTipos, PostTipo } from '../../api/Tipo';

const ConfiguracionTipoPege = () => {
	const [showEliminar, setShowEliminar] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [pustoAccion, setPustoAccion] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dataclase, setdataclase] = useState([]);
	const [nameclase, setNameclase] = useState();
	const [idclase, setidclase] = useState();
	const [alert, setAlert] = useState(false);
	const [mensaje, setMensaje] = useState('');
	// Metodos para el popup de eliminar
	const handleCloseEliminar = () => setShowEliminar(false);
	const handleShowEliminar = async (id) => {
		setShowEliminar(true);
		try {
			const clase = await getTipoByID(id);
			setNameclase(clase.data.name);
			setidclase(clase.data.id);
		} catch (error) {
			console.log(error);
		}
	}


	// Metodos para el popup de Editar
	const handleCloseEdit = () => setShowEdit(false);
	const handleShowEdit = async (id) => {
		setShowEdit(true);
		setPustoAccion(true);
		try {
			const clase = await getTipoByID(id);
			setNameclase(clase.data.name);
			setidclase(clase.data.id);
		} catch (error) {
			console.log(error);
		}

	};

	// Metodos para el popup de agragar
	//const handleCloseadd = () => setShowEdit(false);
	const handleShowadd = () => {
		setShowEdit(true)
		setPustoAccion(false);
		setNameclase('');
	};

	const handleclaseEditChange = (e) => {
		setNameclase(e.target.value);
	}

	// Metodo eliminar una Clase
	const eliminarClase = async () => {
		try {
			setPustoAccion(true);
			setShowEliminar(false);
			const clase = await EliminarTipo(idclase);
			setAlert(true);
			setMensaje(clase.message);
			fetchTipo();
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setMensaje('')
			setAlert(false)
		}, [5000]);
	};

	// Metodo editar una clase
	const editarClase = async () => {
		try {
			const claseEdit = await EditarTipo(idclase, nameclase);
			console.log(claseEdit)
			//setPustoAccion(true);
			setShowEdit(false);
			setAlert(true)
			setMensaje(claseEdit.msg);
			fetchTipo();
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setMensaje('')
			setAlert(false)
		}, [5000]);
	};

	const fetchTipo = async () => {
		setLoading(true);
		try {
			const clases = await getTipos();
			setdataclase(clases.data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}


	useEffect(() => {

		fetchTipo();

	}, [])



	const aggClase = async () => {
		try {
			const clase = await PostTipo(nameclase);
			setMensaje(clase.msg)

			if (clase.success) {
                const nuevoTipo = clase.data;
                setdataclase(prevData => {
                    const nuevaLista = [...prevData, nuevoTipo];
                    return nuevaLista.sort((a, b) => a.name - b.name);
                });
				setShowEdit(false);
				setAlert(true);
				fetchTipo();
			}

		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setMensaje('')
			setAlert(false)
		}, [5000]);
	}


	return (
		<Fragment>

			<div className='d-flex justify-content-center'>
				<AlertComponent styleGroup="bg-soccess color-white" mensaje={mensaje} activaalert={alert} />
			</div>
			<HeaderComponent styleGroup="">
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">TIPO MEDERA</TitleH2Component>
				<button onClick={handleShowadd} type="button" className={`${stylebtn.btn} ${stylebtn.primary} `}>
					Agregar
				</button>
			</HeaderComponent>

			<div className="conatiner-global pt-3 mb-5 gy-5">
				{loading ? (
					<LoaderComponent styleGroup="mt-5" />
				) : <div className="container">
					<div className="row d-flex justify-content-end">
						<div className="table-responsive mt-4">
							<table className="table table-bordered tableGeneral">
								<thead>
									<tr>
										<th scope="col " className="color-madera">
											#
										</th>
										<th scope="col" className="color-madera">
											Nombre
										</th>
									</tr>
								</thead>
								{dataclase.length !== 0 ? (< tbody >
									{dataclase?.map((item, index) => {
										return (
											<tr key={index}>
												<th>{index + 1}</th>
												<td>{item.name}</td>
												<td>
													<button
														className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex`}
														onClick={() => { handleShowEdit(item.id) }}
													>
														{' '}
														<Icon icon="raphael:edit" />
													</button>
												</td>
												<td>
													<button
														className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-danger`}
														onClick={() => { handleShowEliminar(item.id) }}
													>
														{' '}
														<Icon icon="majesticons:delete-bin" />
													</button>
												</td>
											</tr>
										)
									})}
								</ tbody >) :
									<tbody>
										<tr>
											<td colSpan={4} className='text-center'>Sin Clases</td>
										</tr>
									</tbody>}
							</table>
						</div>
						<div className='d-flex justify-content-end'>
							<Link
								className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-dark`}
								to={'/configuracion'}
							>
								{' '}
								<Icon icon="icon-park-solid:back" />
							</Link>
						</div>
					</div>
				</div>}
			</div>

			{/** modal editar informacion de caja menor**/}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo={`${pustoAccion ? 'Editar' : 'Agregar'} Clase`}
					styleGroup={`d-flex align-items-center modal-general `}
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEdit}
					handleClose={handleCloseEdit}
				>
					<form action="">
						{!pustoAccion && <ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>}

						<div className="container">
							<div className="row">
								<div className="col-12 d-flex align-items-center mb-3 justify-content-between">
									<TitleH4Component styleGroup="mb-0 me-3">Nombre</TitleH4Component>
									<InputComponent styleLabel="fw600 WorkSans" styleGroup=" mb-2" htmlFor="bloque" type="text" value={nameclase} onChange={handleclaseEditChange} />
								</div>
							</div>
						</div>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEdit} className={`btn`}>
								Cancelar
							</button>
							<button type="button" onClick={pustoAccion ? editarClase : aggClase} className={` btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
								OK
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>

			{/** modal eliminar un clase y tipo**/}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEliminar}
					handleClose={handleCloseEliminar}
				>
					<form action="">
						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">{`Seguro que quiere eliminar la clase ${nameclase} ?`}</TitleH2Component>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEliminar} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button type="button" className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`} onClick={eliminarClase}>
								Si
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>
		</Fragment >
	);
};
export default ConfiguracionTipoPege;
