
import React from "react";
import ReactPaginate from 'react-paginate';
import { Icon } from '@iconify/react';
import style from '../styles/paginador.module.scss';

const PaginadorComponent = ({ pages, setCurrentPage, styleGroup }) => {
    //const [pageSelected, setPageSelected] = useState(0);
    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        //setPageSelected(selectedPage.selected + 1);
        //setCurrentPage(selectedPage.selected + 1);
    };
    return (
        <div className={`${style.paginador} container ${styleGroup}`}>
            <ReactPaginate
                breakLabel="..."
                nextLabel={<Icon icon="bxs:right-arrow" />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pages}
                previousLabel={<Icon icon="bxs:left-arrow" />}
                initialPage={0}
                renderOnZeroPageCount={null}
                containerClassName={`${style.container_paginate} pagination`}
                pageLinkClassName={style.btn_number}
                //previousLinkClassName={pageSelected === 1 ? style.btn_inactivo : style.btn_previous}
                //nextLinkClassName={pageSelected === pages ? style.btn_inactivonext : style.btn_next}
                activeLinkClassName={style.active}
                breakLinkClassName={style.break}
                previousLinkClassName={style.btn_previous}
                nextLinkClassName={style.btn_next}
                marginPagesDisplayed={2}
            />

        </div>
    )
}

export default PaginadorComponent;