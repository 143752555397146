import instance from "../config";

// metodo para trae toda las clases

const getClases = async () => {

    try {
        const response = await instance.get('/api/class');
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getClaseByID = async (id) => {
    try {
        const response = await instance.get(`/api/class/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

// Metodo editar clase
const EditarClase = async (id, name) => {
    try {
        const response = await instance.put(`/api/class/${id}`, { name });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// Metodo de eliminar el bloque

const EliminarClase = async (id) => {
    try {
        const response = await instance.delete(`/api/class/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}

// crear un puesto a su blouqe 

const PostClase = async (name) => {
    try {
        const response = await instance.post('/api/class', { name });
        return response.data;
    } catch (error) {
        return error.response.data;

    }
}

export {
    getClases,
    EditarClase,
    EliminarClase,
    PostClase,
}