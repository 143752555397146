import React from "react";
import { Modal } from "react-bootstrap";
import TitleH2Component from "./TitleH2Component";

const ModalGeneralComponent = (props) => {
    const {handleClose, show,styleGroup,styleTitle,ModalHeader, titulo,styleBody }=props;
    return (
        <Modal show={show} onHide={handleClose} className={`${styleGroup}`}>
            {ModalHeader && <Modal.Header className="d-flex justify-content-center">
                <TitleH2Component styleGroup={`${styleTitle}`}>{titulo}</TitleH2Component>
            </Modal.Header>}
            <Modal.Body className={styleBody}>
                {props.children}
            </Modal.Body>

        </Modal>
    )
}

export default ModalGeneralComponent;