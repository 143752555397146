import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [usercanActivate, setUsercanActivate] = useState(null); // Puedes inicializarlo con el valor que desees
    const [numCarrito, setNumCarrito] = useState(0);
    const [carrito, setCarrito] = useState([]);
   

    useEffect(() => {
        const carritoGuardado = JSON.parse(localStorage.getItem('carrito')) || [];
        if (carritoGuardado.length > 0) {
            setCarrito(carritoGuardado);
            setNumCarrito(carritoGuardado.length);
        }
    }, []);

    const value = {
        usercanActivate,
        setUsercanActivate,
        numCarrito,
        setNumCarrito,
        carrito,
        setCarrito,
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};