
import instance from "../config";


const Login = async (username, password) => {
    const { data } = await instance.post('/auth/login', { username, password, }) // localhost:4000/v1/auth/login 
    return data;
};


const updatePassword = async (id, data) => {
    try {
        const response = await instance.put(`/auth/login/${id}`, data);
        return response.data;
    } catch (error) {
        return error.response.data;
    }

}


const Logout = () => {
    const token = sessionStorage.getItem('token');
    sessionStorage.removeItem(token);
    sessionStorage.removeItem('token');
    localStorage.removeItem('iduser');

}

export {
    Login,
    Logout,
    updatePassword,
}