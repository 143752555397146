import instance from '../config';

const getEmpleados = async (params) => {
	try {
		const response = await instance.get('/api/empleado',{params});
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
};

const PostEmpleados = async (idPersona, cargo, salario) => {
	try {
		const response = await instance.post('/api/empleado', { idPersona, cargo, salario });
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

const PostPersona = async (data) => {
	try {
		const response = await instance.post('/api/persona', data);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

// Metodo para eliminar un empleado

const EliminarEmpleado = async (idEmpl) => {
	try {
		const response = await instance.delete(`/api/empleado/${idEmpl}`);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

// metodo para editar la persona y el empleado

const EditarEmpleado = async (idEmpl, cargo, salario) => {
	try {
		const response = await instance.put(`/api/empleado/${idEmpl}`, { cargo, salario });
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

// metodo para editar la persona 
const EditarPersona = async (idP, data) => {
	try {
		const response = await instance.put(`/api/persona/${idP}`, data);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

// metodo para obtener una nomina para un empleado
const obtenerNomina = async (idEmpl,params) => {
	try {
		const response = await instance.get(`/api/empleado/${idEmpl}`,{params});
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
};

const PostEmpleadosDetalle = async (
	empleadoId,
	horaEntrada,
	inicioReceso,
	finReceso,
	horaSalida,
	adelanto,
	estadoPago
) => {
	try {
		const response = await instance.post('/api/nomina', {
			empleadoId,
			horaEntrada,
			inicioReceso,
			finReceso,
			horaSalida,
			adelanto,
			estadoPago,
		});
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

const actualizarNomina = async (id, data) => {
	try {
		const response = await instance.put(`/api/nomina/${id}`, data);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};




const verAbonoEmpleado = async () => {
	try {
		const response = await instance.get(`/api/abonoempleado`);
		return response.data;
	} catch (error) {
		return [];
	}
};
const PostAbonoNomina = async (empleadoId, monto) => {
	try {
		const response = await instance.post('/api/abonoempleado', { empleadoId, monto });
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

const actualizarAbono = async (id, monto) => {
	try {
		const response = await instance.put(`/api/abonoempleado/${id}`, { monto });
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

// Metodo para eliminar un abono 
const EliminarAbono = async (id) => {
	try {
		const response = await instance.delete(`/api/abonoempleado/${id}`);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};



// Metodo para  pagar la nomina
const PostpagoNomina = async (dataNomina ) => {

	try {
		const response = await instance.post('/api/pagonomina', {dataNomina});
		return response.data;
	} catch (error) {
		return error.response.data;

	}
}

// Metodo para eliminar un abono 
const EliminarRegistroNomina = async (id) => {
	try {
		const response = await instance.delete(`/api/nomina/${id}`);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

// Metodo para eliminar todo los abonos de un empleado
const eliminarAbonos = async (empleadoId) => {
	
	try {
		const response = await instance.delete(`/api/todo/abonoempleado/${empleadoId}`);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

// Metodo para reset adelantos
const resetAdelantosNomina = async (dataNomina ) => {

	try {
		const response = await instance.post('/api/resetnomina', {dataNomina});
		return response.data;
	} catch (error) {
		return error.response.data;

	}
}

export {
	getEmpleados,
	PostEmpleados,
	PostPersona,
	EliminarEmpleado,
	EditarEmpleado,
	EditarPersona,
	obtenerNomina,
	PostEmpleadosDetalle,
	actualizarNomina,
	verAbonoEmpleado,
	PostAbonoNomina,
	actualizarAbono,
	EliminarAbono,
	PostpagoNomina,
	EliminarRegistroNomina,
	eliminarAbonos,
	resetAdelantosNomina,
};
