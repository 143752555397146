import React, { Fragment, useState } from "react";
import BarraLateralComponent from '../components/BarraLateralComponent';
import { Icon } from '@iconify/react';
import styleBtn from '../styles/btn.module.scss';
import PictureComponent from "../components/PictureComponent";
import TitleH4Component from "../components/TitleH4Component";
const RouterPrivate = ({ canActivate, component }) => {
    const [controlMenu, setControlMenu] = useState(false);
    let componente;
    if (canActivate) {
        componente = component;
    } else {
        window.location.href = "/"
    }

    const abrirMenu = () => {
        setControlMenu(!controlMenu);
    }


    return (
        <Fragment>
            <div className='content-app'>
            {controlMenu ? <div className={'containerModal'}></div>:null}
                <div className=" header-movil">
                    <button type="button" onClick={abrirMenu} className={`${styleBtn.btn_desnudo} ${styleBtn.icon}`}><Icon icon="ic:baseline-menu" /></button>
                    <div className={`d-flex align-items-center`}>
                        <TitleH4Component styleGroup="box-text color-dark fw700 roboto me-2 mb-0">Maderas el paca</TitleH4Component>
                        <PictureComponent src="/img/logo.png" alt="logo" styleGroup={`w-auto imgLogo`} />
                    </div>
                </div>

                <div className={`menu ${controlMenu ? 'active' : ''}`}>
                    <div className="barra-lateral">
                        <BarraLateralComponent setControlMenu={setControlMenu} />
                    </div>
                </div>
                <div className='content mt-3 '>
                    {componente}
                </div>
            </div>
        </Fragment>
    )
}

export default RouterPrivate;