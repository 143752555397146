import axios from 'axios';

axios.defaults.headers.common['authorization'] = 'Bearer ' + sessionStorage.getItem('token');
// la url de la api
const instance = axios.create({
	//baseURL: 'http://localhost:4000/v1/',
	baseURL: 'https://api.maderaselpaca.com/v1/',
	headers: {
		'Content-Type': 'application/json',
		// Añadir más encabezados si es necesario
	},
	// Si es necesario, puedes agregar credenciales, tiempo de espera, etc.
	// withCredentials: true,
	// timeout: 1000,
	//  withCredentials: true,
});
export default instance;
