import instance from '../config';
// Metodo para filtrar los productos
const FiltrarProducto = async (params) => {
    try {
        const response = await instance.get('/filter/producto/', { params });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// Metodo para filtrar los inventarios
const Filtrarinventario = async (params) => {
    try {
        const response = await instance.get('/filter/inventario/', { params });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// Metodo para filtrar las cajas menores
const Filtrarcajamenor = async (params) => {
    try {
        const response = await instance.get('/filter/cajamenor/', { params });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// Metodo para filtrar la nomina de los empleados
const Filtrarnomina = async (params) => {
    try {
        const response = await instance.get('/filter/nomina/', { params });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// Metodo para filtrar los abonos de los empleados
const FiltrarabonosEmbleados = async (params) => {
    try {
        const response = await instance.get('/filter/abonoEmplado/', { params });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// Metodo para filtrar los abonos de los empleados
const Filtrarabonosfactura = async (params) => {
    try {
        const response = await instance.get('/filter/abonoFactura/', { params });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};



export {
    FiltrarProducto,
    Filtrarinventario,
    Filtrarcajamenor,
    Filtrarnomina,
    FiltrarabonosEmbleados,
    Filtrarabonosfactura,
}