import React, { Fragment, useState } from 'react';
import TitleH2Component from '../../components/TitleH2Component';
import HeaderComponent from '../../components/HeaderComponent';
import InputComponent from '../../components/InputComponent';
import stylegeneral from '../../styles/general.module.scss';
import stylebtn from '../../styles/btn.module.scss';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Logout, updatePassword } from '../../api/Autenticacion';
import ParrafoComponent from '../../components/ParrafoComponent';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';

const CambiarClavePage = () => {
	const [mensaje, setMensaje] = useState('');
	const [alert, setAlert] = useState(false);
	const [formData, setFormData] = useState({
		password: '',
		nuevaPassword: '',
		confirmaPassword: ''
	});

	// Manejar el cambio en el input
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleCloseEdit = () => {
		setAlert(false);
		Logout();
		window.location.href = "/"
	};
	const handleShowEdit = () => { setAlert(true) }

	const cambioClave = async () => {
		try {
			const iduser = localStorage.getItem('iduser');
			const user = await updatePassword(iduser, formData);
			console.log('info', user);
			if (!user.success) {
				setMensaje(user.msg);
			} else {
				handleShowEdit();

			}
		} catch (error) {
			console.log(error);
		}

		setTimeout(() => {
			setMensaje('');
		}, 5000);
	}
	return (
		<Fragment>
			<HeaderComponent styleGroup="">
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">CAMBIAR CONTRTASEÑA</TitleH2Component>
			</HeaderComponent>
			<div className="conatiner-global pt-3 mb-5 mt-4">
				<div className="container-fluid grid">
					<div className=" d-flex align-items-center justify-content-center">
						<form action="" className={`${stylegeneral.form_w}`}>

							<InputComponent
								label="Contraseña actual"
								placeholder="Ingrese contraseña actual: "
								styleLabel="fw600 WorkSans "
								htmlFor="bloque"
								type="password"
								styleGroup="mb-3"
								value={formData.password}
								name="password"
								onChange={handleInputChange}
							/>

							<InputComponent
								label="Contraseña nueva"
								placeholder="Ingrese contraseña nueva: "
								styleLabel="fw600 WorkSans"
								htmlFor="bloque"
								type="password"
								styleGroup="mb-3"
								value={formData.nuevaPassword}
								name="nuevaPassword"
								onChange={handleInputChange}
							/>

							<InputComponent
								label="Confirme contraseña"
								placeholder="confirme contraseña: "
								styleLabel="fw600 WorkSans"
								htmlFor="bloque"
								type="password"
								styleGroup="mb-3"
								value={formData.confirmaPassword}
								name="confirmaPassword"
								onChange={handleInputChange}
							/>
							<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>

							<div className="d-flex justify-content-between">
								<button onClick={cambioClave} type="button" className={`${stylebtn.btn} ${stylebtn.primary} `}>
									OK
								</button>

								<Link
									className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-dark`}
									to={'/configuracion'}
								>
									{' '}
									<Icon icon="icon-park-solid:back" />
								</Link>
							</div>
						</form>
					</div>
				</div>
			</div>

			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={alert}
					handleClose={handleCloseEdit}
				>
					<form action="">
						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">{`La contraseña se cambio con exito inicie sesion`}</TitleH2Component>

						<div className="d-flex justify-content-end">

							<button type="button" className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`} onClick={handleCloseEdit}>
								Ok
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>
		</Fragment>
	);
};
export default CambiarClavePage;
