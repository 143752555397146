import React, { Fragment, useEffect, useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import InputComponent from '../../components/InputComponent';
import stylebtn from '../../styles/btn.module.scss';
import { Icon } from '@iconify/react/dist/iconify.js';
import PaginadorComponent from '../../components/PaginadorComponent';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';
import TitleH4Component from '../../components/TitleH4Component';
import { Link } from 'react-router-dom';
import {
	EditarEmpleado,
	EditarPersona,
	EliminarEmpleado,
	getEmpleados,
	PostEmpleados,
	PostEmpleadosDetalle,
	PostPersona,
} from '../../api/Empleado';
import LoaderComponent from '../../components/LoaderComponent';
import ParrafoComponent from '../../components/ParrafoComponent';
import AlertComponent from '../../components/AlertComponent';
import MoneyInputComponent from '../../components/MoneyInputComponent';
import { BuscarCliente } from '../../api/ApiVenta';

const EmpleadoPage = () => {
	//Data de paginador
	const [currentPage, setCurrentPage] = useState(0);
	const [show, setShow] = useState(false);
	const [showEliminar, setShowEliminar] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showHora, setShowHora] = useState(false);
	const [loading, setLoading] = useState(false);
	const [mensaje, setMensaje] = useState('');
	const [alert, setAlert] = useState(false);
	const [currentEmpleado, setCurrentEmpleado] = useState([]);
	const [currentPersona, setCurrentPersona] = useState([]);
	const [control, setcontrol] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [formDataEmpleado, setFormDataEmpleado] = useState({
		cargo: '',
		salario: '',
	});

	const [formDataPersona, setFormDataPersona] = useState({
		cedula: '',
		nombre: '',
		apellido: '',
		direccion: '',
		celular: '',
	});

	const [formDataEmpleadoD, setFormDataEmpleadoD] = useState({
		horaEntrada: '07:00',
		horaSalida: '16:30',
		inicioReceso: '12:30',
		finReceso: '13:30',
		adelanto: 0,
		estadoPago: false,
	});

	// Manejar el cambio en el input
	const handleInputChangeEmpleado = (event) => {
		const { name, value } = event.target;
		setFormDataEmpleado({
			...formDataEmpleado,
			[name]: value,
		});
	};

	// Manejar el cambio en el input
	const handleInputChangePersona = (event) => {
		const { name, value } = event.target;
		setFormDataPersona({
			...formDataPersona,
			[name]: value,
		});
	};

	// Manejar el cambio en el input para la nomina
	const handleInputChangeEmpleadonomina = (event) => {
		const { name, value } = event.target;
		setFormDataEmpleadoD({
			...formDataEmpleadoD,
			[name]: value,
		});
	};

	//const [data] = useState([...Array(12).keys()]); // Simulando datos, un array de 100 elementos
	const [data, setData] = useState([]);
	const itemsPerPage = 7;
	const pageCount = Math.ceil(data.length / itemsPerPage);
	const currentPageDataEmpleado = data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

	// metodos para el popup de eliminar
	const handleCloseEliminar = () => setShowEliminar(false);
	const handleShowEliminar = (obj) => {
		setShowEliminar(true);
		setCurrentEmpleado(obj);
	};

	//metodo para mostrar el popup de editar
	const handleShowEditar = (item) => {
		setShow(true);
		setShowEdit(true);
		setCurrentEmpleado(item);
		setFormDataPersona({
			cedula: item?.Persona?.cedula,
			nombre: item?.Persona?.nombre,
			apellido: item?.Persona?.apellido,
			direccion: item?.Persona?.direccion,
			celular: item?.Persona?.celular,
		});

		setFormDataEmpleado({
			cargo: item?.cargo,
			salario: item?.salario,
		});
	};

	//metodo para registrar horas laborales
	const handleShowHora = (item) => {
		setShowHora(true);
		setCurrentEmpleado(item);
	};
	const handleCloseHora = () => setShowHora(false);

	const handleClose = () => {
		setShow(false);
		setcontrol(false);
	};

	const handleShow = () => {
		setShow(true);
		setShowEdit(false);
		setFormDataEmpleado({
			cargo: '',
			salario: '',
		});

		setFormDataPersona({
			cedula: '',
			nombre: '',
			apellido: '',
			direccion: '',
			celular: '',
		});
	};

	const fetchData = async (filters = {}) => {
		setLoading(true);
		try {
			const res = await getEmpleados(filters);
			setData(res?.data);
		} catch (error) {
			console.error('Error algo esta mal:', error);
		} finally {
			setLoading(false);
		}
	};

	// para ejecutar las metodos automaticos
	useEffect(() => {
		fetchData();
	}, []);

	const addEmpleados = async (e) => {
		e.preventDefault();
		try {
			// verifico que todo los datos esten llenos

			if (
				formDataPersona.cedula === '' ||
				formDataPersona.nombre === '' ||
				formDataPersona.apellido === '' ||
				formDataEmpleado.cargo === '' ||
				formDataEmpleado.salario === '' ||
				formDataEmpleado.cargo === undefined ||
				formDataEmpleado.salario === undefined
			) {
				setMensaje('Los campos que estan vacios son obligatorios *');
			} else {
				// si la perosna no exite en la base de datos
				if (!control || control == false) {
					const resP = await PostPersona(formDataPersona);
					setMensaje(resP.msg);
					if (resP.success) {
						setAlert(true);
						await PostEmpleados(resP.data.idP, formDataEmpleado.cargo, formDataEmpleado.salario);
						handleClose();
						fetchData();
						setFormDataEmpleado({
							cargo: '',
							salario: '',
						});

						setFormDataPersona({
							cedula: '',
							nombre: '',
							apellido: '',
							direccion: '',
							celular: '',
						});
					}
				} else if (currentEmpleado?.length === 0) {
					await PostEmpleados(currentPersona?.idP, formDataEmpleado.cargo, formDataEmpleado.salario);
					handleClose();
					fetchData();
					setFormDataEmpleado({
						cargo: '',
						salario: '',
					});

					setFormDataPersona({
						cedula: '',
						nombre: '',
						apellido: '',
						direccion: '',
						celular: '',
					});
				} else {
					setMensaje('El empleado ya existe');
				}
			}
		} catch (error) {
			console.log(error);
		}

		setTimeout(() => {
			setAlert(false);
			setMensaje('');
		}, 5000);
	};

	const eliminarEmpleado = async () => {
		try {
			const res = await EliminarEmpleado(currentEmpleado?.idEmpl);
			if (res.success) {
				setAlert(true);
				setMensaje(res.message);
				fetchData();
				handleCloseEliminar();
			}
			console.log(res);
		} catch (error) {
			console.log(error);
		}

		setTimeout(() => {
			setAlert(false);
		}, 3000);
	};

	const editarEmpleado = async (e) => {
		e.preventDefault();
		try {
			const resE = await EditarEmpleado(currentEmpleado?.idEmpl, formDataEmpleado.cargo, formDataEmpleado.salario);
			const resP = await EditarPersona(currentEmpleado?.Persona?.idP, formDataPersona);
			console.log(resP);
			console.log(resE);
			if (resE.success && resP.success) {
				setAlert(true);
				setMensaje(resE.msg);
				fetchData();
				handleClose();
			}
		} catch (error) {
			console.log(error);
		}

		setTimeout(() => {
			setAlert(false);
			setMensaje('');
		}, 3000);
	};

	// metodo para craer la nomina del empleado
	const craernomina = async () => {
		try {
			const res = await PostEmpleadosDetalle(
				currentEmpleado?.idEmpl,
				formDataEmpleadoD.horaEntrada,
				formDataEmpleadoD.inicioReceso,
				formDataEmpleadoD.finReceso,
				formDataEmpleadoD.horaSalida,
				formDataEmpleadoD.adelanto,
				formDataEmpleadoD.estadoPago
			);
			if (res.success) {
				handleCloseHora();
				setAlert(true);
				setMensaje(res.msg);
			}
			console.log('respuesta de nomina', res);
		} catch (error) {
			console.log(error);
		}

		setTimeout(() => {
			setAlert(false);
			setMensaje('');
		}, 3000);
	};

	// metodo para consultar la cc
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			consultarCedula();
		}
	};

	const consultarCedula = async () => {
		try {
			const res = await BuscarCliente(formDataPersona.cedula);
			if (res.success) {
				setFormDataPersona({
					cedula: res?.data?.cedula,
					nombre: res?.data?.nombre,
					apellido: res?.data?.apellido,
					direccion: res?.data?.direccion,
					celular: res?.data?.celular,
				});
				setFormDataEmpleado({
					cargo: res?.data?.Empleados[0]?.cargo,
					salario: res?.data?.Empleados[0]?.salario,
				});

				setCurrentPersona(res?.data);
				setCurrentEmpleado(res?.data?.Empleados);
			} else {
				setMensaje(res.msg);
				setFormDataPersona({
					cedula: formDataPersona.cedula,
					nombre: '',
					apellido: '',
					direccion: '',
					celular: '',
				});
				setFormDataEmpleado({
					cargo: '',
					salario: '',
				});
			}
			setcontrol(res.success);
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setMensaje('');
		}, 3000);
	};

	// metodo para buscar el empleado

	const buscarEmpleado = async (e) => {
		e.preventDefault();
		const filters = {
			searchQuery: searchQuery, 
		};

		fetchData(filters);
	}

	return (
		<Fragment>
			<div className="d-flex justify-content-center">
				<AlertComponent styleGroup="bg-soccess color-white" mensaje={mensaje} activaalert={alert} />
			</div>
			<HeaderComponent>
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">EMPLEADOS</TitleH2Component>
				<button type="button" onClick={handleShow} className={`${stylebtn.btn} ${stylebtn.primary} `}>
					AGREGAR EMPLEADO
				</button>
			</HeaderComponent>

			{loading ? (
				<LoaderComponent styleGroup="mt-5" />
			) : (
				<div className="conatiner-global pt-3 mb-5">
					<section>
						<div className="container">
							<form action="" onSubmit={buscarEmpleado}>
								<div className="row gy-4 d-flex justify-content-end align-items-end">
									<div className="col-12 col-md-6 col-xl-3">
										<InputComponent
											placeholder="Ingresar documento"
											label="Buscar Empleado"
											styleLabel="fw600 WorkSans"
											htmlFor="bloque"
											type="search"
											value={searchQuery}
											onChange={(e) => setSearchQuery(e.target.value)}
										/>
									</div>
									<div className="col-12 col-md-6 col-xl-2">
										<button type="submit" className={`${stylebtn.btn} ${stylebtn.primary} `}>
											Buscar
										</button>
									</div>
								</div>
							</form>
						</div>
					</section>

					<section>
						<div className="table-responsive mt-4">
							<table className="table table-bordered tableGeneral">
								<thead>
									<tr>
										<th scope="col " className="color-madera">
											#
										</th>
										<th scope="col " className="color-madera">
											CC
										</th>
										<th scope="col" className="color-madera">
											Nombres
										</th>
										<th scope="col" className="color-madera">
											Apellidos
										</th>
										<th scope="col" className="color-madera">
											Celular
										</th>
										<th scope="col" className="color-madera">
											Cargo
										</th>
										{/**<th scope="col" className="color-madera">
											Adelanto
										</th>
										<th scope="col" className="color-madera">
											Abono
										</th>
										<th scope="col" className="color-madera">
											Debiendo
										</th>*/}
										<th scope="col" className="color-madera">
											Sueldo
										</th>
									</tr>
								</thead>
								{currentPageDataEmpleado && currentPageDataEmpleado?.length !== 0 ? (
									<tbody>
										{currentPageDataEmpleado?.map((item, index) => {
											return (
												<tr key={item?.idEmpl}>
													<th>{index + 1}</th>
													<td>{item?.Persona?.cedula}</td>
													<td>{item?.Persona?.nombre}</td>
													<td>{item?.Persona?.apellido}</td>
													<td>{item?.Persona?.celular}</td>
													<td>{item?.cargo}</td>
													{/**<td>$falta</td>
													<td>$falta</td>
													<td>$falta</td>*/}
													<td>${Number(item?.salario).toLocaleString('es-ES')}</td>

													<td>
														<Link
															to={`/detalleempleado/${item?.idEmpl}`}
															className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex`}
														>
															<Icon className="color-dark" icon="marketeq:agenda" />
														</Link>
													</td>
													<td>
														<button
															onClick={() => handleShowHora(item)}
															className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-danger`}
														>
															<Icon className="color-dark" icon="zondicons:calendar" />
														</button>
													</td>

													<td>
														<button
															onClick={() => handleShowEditar(item)}
															className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex`}
														>
															{' '}
															<Icon icon="raphael:edit" />
														</button>
													</td>
													<td>
														<button
															onClick={() => {
																handleShowEliminar(item);
															}}
															className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-danger`}
														>
															{' '}
															<Icon icon="majesticons:delete-bin" />
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								) : (
									<tbody>
										<tr>
											{' '}
											<td colSpan={12} className="text-center">
												SIN EMPLEADOS
											</td>
										</tr>
									</tbody>
								)}
							</table>
						</div>
					</section>

					{pageCount > 1 && (
						<PaginadorComponent
							setCurrentPage={setCurrentPage}
							pages={pageCount}
							styleGroup="d-flex justify-content-center pt-4"
						/>
					)}
				</div>
			)}
			{/** Modal para registro de hora */}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo="REGISTRO DE HORAS LABORALES"
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showHora}
					handleClose={handleCloseHora}
				>
					<div className="container">
						<form action="">
							{!alert && (
								<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>
							)}
							<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-1">
								{currentEmpleado?.Persona?.nombre}
							</TitleH2Component>
							<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-1">
								{currentEmpleado?.Persona?.apellido}{' '}
							</TitleH4Component>
							<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-4">
								CC: {currentEmpleado?.Persona?.cedula}
							</TitleH4Component>
							<div className="d-flex align-items-center mb-3 justify-content-between">
								<TitleH4Component styleGroup="mb-0 me-3">HORA DE ENTRADA</TitleH4Component>
								<InputComponent
									styleLabel="fw600 WorkSans"
									htmlFor="bloque"
									type="time"
									value={formDataEmpleadoD.horaEntrada}
									name="horaEntrada"
									onChange={handleInputChangeEmpleadonomina}
								/>
							</div>

							<div className="d-flex align-items-center justify-content-between">
								<TitleH4Component styleGroup="mb-0 me-3">HORA DE SALIDA</TitleH4Component>
								<InputComponent
									styleLabel="fw600 WorkSans"
									htmlFor="bloque"
									type="time"
									value={formDataEmpleadoD.horaSalida}
									name="horaSalida"
									onChange={handleInputChangeEmpleadonomina}
								/>
							</div>

							<div className="d-flex justify-content-end mt-4">
								<button type="button" onClick={handleCloseHora} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
									Cancelar
								</button>
								<button type="button" onClick={craernomina} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
									Agregar
								</button>
							</div>
						</form>
					</div>
				</ModalGeneralComponent>
			</section>

			{/** modal editar registro de empleado**/}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo={`${showEdit ? 'EDITAR' : 'REGISTRAR'} EMPLEADO`}
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={show}
					handleClose={handleClose}
					handleShow={handleShow}
				>
					<div className="container">
						<form action="" onSubmit={showEdit ? editarEmpleado : addEmpleados}>
							{!alert && (
								<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>
							)}

							<div className="row g-4">
								<div className="col-12 col-sm-6">
									<input
										type="text"
										htmlFor="cudula"
										className="form-control fw600 WorkSans mb-4"
										placeholder="Ingrese la cc"
										name="cedula"
										value={formDataPersona.cedula}
										onChange={handleInputChangePersona}
										onKeyDown={handleKeyDown}
									/>
								</div>
								<div className="col-12">
									<InputComponent
										placeholder="Nombres"
										styleLabel="fw600 WorkSans"
										htmlFor="bloque"
										type="text"
										value={formDataPersona.nombre}
										name="nombre"
										onChange={handleInputChangePersona}
									/>
								</div>
								<div className="col-12 col-sm-6">
									<InputComponent
										placeholder="Apellidos"
										styleLabel="fw600 WorkSans"
										htmlFor="bloque"
										type="text"
										value={formDataPersona.apellido}
										name="apellido"
										onChange={handleInputChangePersona}
									/>
								</div>

								<div className="col-12 col-sm-6">
									<InputComponent
										placeholder="Cargo"
										styleLabel="fw600 WorkSans"
										htmlFor="bloque"
										type="text"
										value={formDataEmpleado.cargo}
										name="cargo"
										onChange={handleInputChangeEmpleado}
									/>
								</div>
								<div className="col-12 col-sm-6">
									<MoneyInputComponent
										placeholder="Salario por día"
										styleLabel="fw600 WorkSans"
										htmlFor="bloque"
										type="number"
										value={formDataEmpleado.salario}
										name="salario"
										onChange={handleInputChangeEmpleado}
									/>
								</div>
								<div className="col-12 col-sm-6">
									<InputComponent
										placeholder="Celular"
										styleLabel="fw600 WorkSans"
										htmlFor="bloque"
										type="number"
										value={formDataPersona.celular}
										name="celular"
										onChange={handleInputChangePersona}
									/>
								</div>
								<div className="col-12 col-sm-6">
									<InputComponent
										placeholder="Direccion"
										styleLabel="fw600 WorkSans"
										htmlFor="bloque"
										type="text"
										value={formDataPersona.direccion}
										name="direccion"
										onChange={handleInputChangePersona}
									/>
								</div>
							</div>

							<div className="d-flex justify-content-end mt-4">
								<button type="button" onClick={handleClose} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
									Cancelar
								</button>
								{
									<button type="submit" className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
										{showEdit ? 'Editar' : 'Agregar'}
									</button>
								}
							</div>
						</form>
					</div>
				</ModalGeneralComponent>
			</section>

			{/** modal eliminar un empleado**/}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEliminar}
					handleClose={handleCloseEliminar}
					handleShow={handleShowEliminar}
				>
					<form action="">
						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">
							Seguro que quiere eliminar el empleado {currentEmpleado?.Persona?.nombre}{' '}
							{currentEmpleado?.Persona?.apellido}?
						</TitleH2Component>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEliminar} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button
								type="button"
								className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
								onClick={eliminarEmpleado}
							>
								SI
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>
		</Fragment>
	);
};

export default EmpleadoPage;
