import React from 'react';
import Style from '../styles/text.module.scss';
const TitleH4Component = (props) => {
	return (
		<h4
			className={`${Style.h4} ${props.styleGroup}`}
			style={props.style} // Agregamos el atributo style aquí
		>
			{props.children}
		</h4>
	);
};
export default TitleH4Component;
